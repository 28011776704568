import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import 'reactflow/dist/style.css';
import CustomSelect from '../../common/customSelect/CustomSelect';
import CustomFormLabel from '../../common/form-label/CustomFormLabel ';
// import { workflowUseStyles } from '../workflow';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../../redux/reducers/rootReducers';
import { getPrioritiesWithTicketTypeKey } from '../../helpers/helperFunctions';
import { debounce } from 'lodash';
import { unsetNodeErrors } from '../../../redux/actions/Workflow/workflow';
export interface generateNodeValuesResult {
  agentVal:any;
  groupVal:any;
  statusVal:any;
  emailTemplateVal:any;
  ticketTypeVal:any;
  priorityVal:any;
}
export const defaultAgents = [{ id:-1, value:-1, label:"{RoundRobin}" }, {id: -2, value:-2, label:"{Owner}" }];
export const generateNodeValues:any = (allResponses:any,task:any={}) =>{
  console.log("generateNodeValues", allResponses, task);
  const {AgentsList=[],ticketStatus=[],priorities=[],groups=[],templatesList=[],TicketTypes=[]} = allResponses || {};
  const {statusId,groupId,agentId,dynamicAgent,ticketTypeId,priorityId,emailTemplateId,minimumVotesRequired} = task || {};
  const tempObj = {
    agentVal : [...defaultAgents,...AgentsList].find((i:any)=>i.id===agentId || (agentId === 0 && i.label === dynamicAgent)) || null,
    groupVal :  groups.find((i:any)=>i.id===groupId) || null,
    statusVal :  ticketStatus.find((i:any)=>i.id===statusId) || null,
    emailTemplateVal :  templatesList.find((i:any)=>i.id===emailTemplateId) || null,
    ticketTypeVal :  TicketTypes.find((i:any)=>i.id===ticketTypeId) || null,
    priorityVal :  priorities.find((i:any)=>i.id===priorityId) || null,
  }
  return tempObj;
}

const NodeActionsForm:any = (props:any) => {
    const allResponses = useSelector((state:IState)=>state?.TicketsReducer?.allResponses)
    const templatesList = useSelector((state: IState) => state?.EmailTemplatesReducer?.AllEmailTemplates);
    const errors = useSelector((state:IState)=>state?.WorkflowReducer?.errors)

    const dispatch = useDispatch();
    const {nodeConfig} = props;
    
    const [fields, setfields] = useState(`${nodeConfig?.actions || ""}`)

    const [priorityByIdTTID,setPriorityByIdTTID] = useState<any>(getPrioritiesWithTicketTypeKey(allResponses?.priorities))


    const [agentsList, setAgentsList] = useState<any>([]);
    const [priorityList, setPriorityList] = useState<any>([]);
    
    const [agentVal, setAgentVal] = useState<any>(nodeConfig?.dataToPrefill?.agentVal || null);
    const [groupVal, setGroupVal] = useState<any>(nodeConfig?.dataToPrefill?.groupVal || null);
    const [statusVal, setStatusVal] = useState<any>(nodeConfig?.dataToPrefill?.statusVal || null);
    const [emailTemplateVal, setEmailTemplateVal] = useState<any>(nodeConfig?.dataToPrefill?.emailTemplateVal || null);
    const [ticketTypeVal, setTicketTypeVal] = useState<any>(nodeConfig?.dataToPrefill?.ticketTypeVal || null);
    const [priorityVal, setPriorityVal] = useState<any>(nodeConfig?.dataToPrefill?.priorityVal || null);

    useEffect(()=>{
      console.log("NodeActionsFormallResponses",nodeConfig)
      if(nodeConfig?.workflowNodeTaskList?.[0]){
      //   const dataToPrefill:generateNodeValuesResult = generateNodeValues({...allResponses,templatesList : templatesList},nodeConfig?.workflowNodeTaskList?.[0]);
      //   console.log("dataToPrefill",dataToPrefill);
      //   const { agentVal=null, groupVal=null, statusVal=null, emailTemplateVal=null, ticketTypeVal=null, priorityVal=null } = dataToPrefill || {};
      //   setAgentVal(agentVal);
      //   setGroupVal(groupVal);
      //   setStatusVal(statusVal);
      //   setEmailTemplateVal(emailTemplateVal);
      //   setTicketTypeVal(ticketTypeVal);
      //   setPriorityVal(priorityVal);

      //   setPriorityList(priorityByIdTTID?.[ticketTypeVal?.id] || [])
        setAgentsList([...defaultAgents, ...(nodeConfig?.dataToPrefill?.groupVal?.agentsList || [])]);
      }
      
    },[nodeConfig])
    
    const ticketTypeHandler = (val:any) =>{
        console.log("ticketTypeHandler",val,priorityByIdTTID,priorityByIdTTID?.[val?.id])
        setTicketTypeVal(val)
        setPriorityVal(null)
        setPriorityList(priorityByIdTTID?.[val?.id] || [])
        unsetError("hasActionError");
    }
    const groupHandler = (val:any) =>{
        setGroupVal(val)
        setAgentsList([...defaultAgents, ...(val?.agentsList || [])]);
        unsetError("hasActionError")
        setAgentVal(null)
    }
    const unsetError = debounce((name:string="")=>{
      if(errors?.[name]){
        dispatch(unsetNodeErrors([name]))
      }
    },200)
    return (
        <>      
            <Grid
              container
              spacing={1}
            >
              <Grid item md={12}>
                {errors?.hasActionError && <Typography component={"span"} className='txt-danger'>Please choose any one field to continue!</Typography>}
              </Grid>
              {fields?.includes('statusId') &&
                <Grid item xs={12} md={4}>
                  <Box className="">
                    <CustomFormLabel
                      labelName={"Status"}
                      isMandotary={false}
                      classNames='sml-txt'
                    />
                    <CustomSelect
                      isOpen={false}
                      placeholder="Select Status"
                      width={"100%"}
                      name="statusId"
                      isColor={false}
                      isDisabled={false}
                      isError={false}
                      defaultValue={statusVal}
                      options={allResponses?.ticketStatus}
                      handleChange={(e:any)=>{
                        setStatusVal(e);
                        unsetError("hasActionError")
                      }}
                      isMulti={false}
                      helperText={""}
                      customClassNames="sml-txt-dropdown"
                    />
                  </Box>
                </Grid>
              }
              {fields?.includes('groupId') &&
                <Grid item xs={12} md={4}>
                  <Box className="">
                    <CustomFormLabel
                      labelName={"Group"}
                      isMandotary={false}
                      classNames='sml-txt'
                    />
                    <CustomSelect
                      isOpen={false}
                      placeholder="Select Group"
                      width={"100%"}
                      name="groupId"
                      isColor={false}
                      isDisabled={false}
                      isError={false}
                      defaultValue={groupVal}
                      options={allResponses?.groups}
                      handleChange={groupHandler}
                      isMulti={false}
                      helperText={""}
                      customClassNames="sml-txt-dropdown"
                    />
                  </Box>
                </Grid>
              }
              {fields?.includes('agentId') &&
                <Grid item xs={12} md={4}>
                  <Box className="">
                    <CustomFormLabel
                      labelName={"Agent"}
                      isMandotary={false}
                      classNames='sml-txt'
                    />
                    <CustomSelect
                      isOpen={false}
                      placeholder="Select Agent"
                      width={"100%"}
                      name="agentId"
                      isColor={false}
                      isDisabled={false}
                      isError={false}
                      defaultValue={agentVal}
                      options={agentsList}
                      handleChange={(e:any)=>{
                        setAgentVal(e);
                        unsetError("hasActionError")
                      }}
                      isMulti={false}
                      helperText={""}
                      customClassNames="sml-txt-dropdown"
                    />
                      <Typography>Choose Group before choosing an Agent! </Typography>
                  </Box>
                </Grid>
              }
              {fields?.includes('ticketTypeId') &&
                <Grid item xs={12} md={4}>
                    <Box className="">
                      <CustomFormLabel
                        labelName={"Ticket Type"}
                        isMandotary={false}
                        classNames='sml-txt'
                      />
                      <CustomSelect
                        isOpen={false}
                        placeholder="Select Ticket Type"
                        width={"100%"}
                        name="ticketTypeId"
                        isColor={false}
                        isDisabled={false}
                        isError={false}
                        defaultValue={ticketTypeVal}
                        options={allResponses?.TicketTypes}
                        handleChange={ticketTypeHandler}
                        isMulti={false}
                        helperText={""}
                        customClassNames="sml-txt-dropdown"
                      />
                    </Box>
                </Grid>
              }
              {fields?.includes('priorityId') &&
                <Grid item xs={12} md={4}>
  
                  <Box className="">
                    <CustomFormLabel
                      labelName={"Priority"}
                      isMandotary={false}
                      classNames='sml-txt'
                    />
                    <CustomSelect
                      isOpen={false}
                      placeholder="Select Priority"
                      width={"100%"}
                      name="priorityId"
                      isColor={false}
                      isDisabled={false}
                      isError={false}
                      defaultValue={priorityVal}
                      options={priorityList}
                      handleChange={(e:any)=>{
                        setPriorityVal(e);
                        unsetError("hasActionError");
                      }}
                      isMulti={false}
                      helperText={""}
                      customClassNames="sml-txt-dropdown"
                    />
                    <Typography>Choose Ticket Type before choosing a Priority! </Typography>
                  </Box>
                </Grid>
              }
              {fields?.includes('emailTemplateId') &&
              <Grid item xs={12} md={4}>
                  <Box className="">
                    <CustomFormLabel
                      labelName={"Email Template"}
                      isMandotary={false}
                      classNames='sml-txt'
                    />
                    <CustomSelect
                      isOpen={false}
                      placeholder="Select Email Template"
                      width={"100%"}
                      name="emailTemplateId"
                      isColor={false}
                      isDisabled={false}
                      isError={false}
                      defaultValue={emailTemplateVal}
                      options={templatesList || []}
                      handleChange={(e:any)=>{
                        setEmailTemplateVal(e)
                        unsetError("hasActionError")
                      }}
                      isMulti={false}
                      helperText={""}
                      customClassNames="sml-txt-dropdown"
                    />
                    {emailTemplateVal?.from &&
                      <Typography >
                        From : {emailTemplateVal?.from}
                      </Typography>
                    }
                  </Box>
              </Grid>}
            </Grid>
        </>)
}


// const mapStateToProps = (state:IState) => {
//     return ({
//         allResponses: state?.TicketsReducer?.allResponses,
//     })
// };
  
// export default connect(mapStateToProps)(NodeActionsForm);
export default NodeActionsForm;