import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Avatar, Box, Grid, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import moment from "moment";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getTicketByID } from "../../../redux/actions/TicketsManagement/TicketsManagement";
import { IState } from "../../../redux/reducers/rootReducers";
import timeSpanToReadbleFormat from "../../../util/timeSpanToReadbleFormat";
import PopupImageSlider from "../../common/PopupImageSlider/PopupImageSlider";

export const TicketViewPopup = memo(() => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const ticketToShow = useSelector((state: IState) => state?.TicketViewReducer?.info);
    const AllTicketsById = useSelector((state: IState) => state?.TicketViewReducer?.allTicketsById);
    const [activeImgIndex,setActiveImgIndex] = useState(-1);
    const [ticketInfo,setTicketInfo] = useState<any>({});
    useEffect(() => {
        console.log("TicketViewPopupticketToShow",ticketToShow)
        if (ticketToShow?.ticketId) {
            dispatch(getTicketByID(ticketToShow?.ticketId,getTicketByIDCB))
        }else{
            setTicketInfo({})
        }
    }, [ticketToShow?.ticketId])
    const getTicketByIDCB = (resStatus:string, res:any)=>{
        if(resStatus === '0'){
            setTicketInfo(res)
        }
    };
    const onCloseHandler = useCallback(() => {
        dispatch({
            type: "TICKET_VIEW_INFO",
            payload: {}
        })
        setTicketInfo({})
    }, [])
    const viewContent = useMemo(() => <>
        {ticketToShow?.ticketId &&
            <Box className={`${classes.popupHolder} ${classes.textWithSpanTitle}`}>
                <Box className="modal-dialog">
                    <Box className="modal-content">
                        <Box className="modal-header">
                            <Box className={`${classes.row} ${classes.alignItemsCenter}`}>
                                <Box>

                                    <Typography variant="h6" className={`${classes.viewPopupTitle} txtCapitalize`}>

                                        <span className={classes.cardTcktCount}>
                                            <span className="dots whiteBG"></span>
                                            <span>{ticketInfo?.id}</span>
                                            <span className="dots whiteBG dots-right"></span>
                                        </span>
                                        &nbsp;&nbsp;
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography>{ticketInfo?.title}</Typography>
                                </Box>
                                <Box>
                                    <Typography className={`${classes.txtWithLink} ${classes.viewDtls} m-0`} gutterBottom>
                                        <Link to={`/ticketInfoView/${ticketInfo?.id}`}><span>View In Detail</span> <OpenInNewIcon /></Link>
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className={classes.row}>
                                <Box>
                                    <Tooltip
                                        title={`Ticket Status ${ticketInfo?.ticketLogs?.statusVal?.name}`}
                                    >
                                        <Typography
                                            variant="body2"
                                            className={`${classes.title} ${classes.ticketCardTags} ticketManagementsys-status`}
                                        >
                                            {ticketInfo?.ticketLogs?.ticketStatus?.displayName || "-"}
                                            {/* {ticketInfo?.ticketLogs?.statusVal?.name} */}
                                        </Typography>
                                    </Tooltip>
                                </Box>
                                <Box>
                                    <Tooltip
                                        title={`Ticket Source ${ticketInfo?.sourceCode}`}
                                    >
                                        <Typography
                                            variant="body2"
                                            className={`${classes.title} ${classes.ticketCardTags} ${classes.tagBlue}`}
                                        >
                                            {ticketInfo.sourceCode}
                                        </Typography>
                                    </Tooltip>
                                </Box>
                                <Box>
                                    <Tooltip
                                        title={`Ticket Type ${ticketInfo?.ticketLogs?.ticketTypeVal}`}
                                    >
                                        <Typography
                                            variant="body2"
                                            className={`${classes.title} ${classes.ticketCardTags} ${classes.tagWarn} ${classes.TicketTag} whitebck make-ticketBox`}
                                        >
                                            {ticketInfo?.ticketLogs?.ticketType?.displayName || "-"}
                                        </Typography>
                                    </Tooltip>
                                </Box>
                            </Box>
                            <Box className={`${classes.row}`}>
                                <Box
                                    className={`${classes.row} ${classes.alignItemsCenter} sla-txt`}
                                >
                                    <Typography variant="body1" className={classes.primaryTxt}>
                                        | Created Date : &nbsp;
                                    </Typography>
                                    <Typography variant="body1">
                                        <b>
                                            {moment(ticketInfo?.ticketLogs?.createdDateTime).format("L LT")}{" "}
                                        </b>&nbsp;
                                    </Typography>
                                </Box>
                                <Box
                                    className={`${classes.row} ${classes.alignItemsCenter} sla-txt`}
                                >
                                    <Typography variant="body1" className={classes.primaryTxt}>
                                        | SLA : &nbsp;
                                    </Typography>
                                    <Tooltip
                                        title={`SLA Remaining ${timeSpanToReadbleFormat(ticketInfo?.ticketLogs?.slaRemaining, "ss")}`}
                                    >
                                        <Typography variant="body1">
                                            <b
                                                className={`${ticketInfo?.ticketLogs?.slaRemainingInTicks &&
                                                    ticketInfo?.ticketLogs?.slaRemainingInTicks < 0
                                                    ? classes.dangerTxt
                                                    : classes.successTxt
                                                    }`}
                                            >
                                                {ticketInfo?.ticketLogs?.statusVal?.name === "OnHold"
                                                    ? "On-Hold" : timeSpanToReadbleFormat(ticketInfo?.ticketLogs?.slaRemaining, "ss")}&nbsp; &nbsp;
                                            </b>
                                        </Typography>
                                    </Tooltip>
                                </Box>
                                <Typography className="cursor-pointer" onClick={onCloseHandler}>
                                    <CloseIcon className="cursor-pointer" />
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="modal-body">
                            <Grid container spacing={3}>
                                <Grid item xs={6} md={4}>
                                    <Typography className="title-txts">
                                        <span>Title :</span>  {ticketInfo?.ticketLogs?.title}
                                    </Typography>
                                    <Typography className="title-txts">
                                        <span>Description :</span>  {ticketInfo?.ticketLogs?.description}
                                    </Typography>
                                    <Typography className="title-txts">
                                        <span>Note :</span>  {(ticketInfo?.ticketLogs?.note && ticketInfo?.ticketLogs?.note !== "") ? ticketInfo?.ticketLogs?.note : "-"}
                                    </Typography>
                                    <Typography className="title-txts">
                                        <span>First Action Date :</span> {moment(ticketInfo?.ticketLogs?.firstResponseDueDateTime).format("L LT")}
                                    </Typography>
                                    <Typography className="title-txts">
                                        <span>First Action Description :</span>  {(ticketInfo?.ticketLogs?.nextActionDescription && ticketInfo?.ticketLogs?.nextActionDescription !== "") ? ticketInfo?.ticketLogs?.nextActionDescription : "-"}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6} md={4} className='bl-1'>
                                    <Typography className="title-txts">
                                        <span>Primary Contact :</span>  {ticketInfo?.ticketLogs?.contacts?.knownAs || "-"}
                                    </Typography>
                                    {ticketInfo?.ticketLogs?.secondaryContacts && 
                                    <Typography className="title-txts">
                                        <span>Secondary Contact :</span>  {ticketInfo?.ticketLogs?.secondaryContacts?.knownAs ? ticketInfo?.ticketLogs?.secondaryContacts?.knownAs : "-"}
                                    </Typography>
                                    }
                                    <Typography className="title-txts">
                                        <span>Group :</span>  
                                        {ticketInfo?.ticketLogs?.group?.name || "-"}
                                    </Typography>
                                    <Typography className="title-txts">
                                        <span>Owner :</span>
                                        {ticketInfo?.ticketLogs?.agent?.firstName ? `${ticketInfo?.ticketLogs?.agent?.firstName} ${ticketInfo?.ticketLogs?.agent?.lastName}` : "-"}
                                    </Typography>
                                    
                                    <Typography className="title-txts">
                                        <span>Tags :</span>
                                        {(AllTicketsById?.[ticketInfo?.id]?.ticketTagList?.length &&  AllTicketsById?.[ticketInfo?.id]?.ticketTagList?.length > 0) ? 
                                            <>
                                                {AllTicketsById?.[ticketInfo?.id]?.ticketTagList.map((i: any) => {
                                                    return (<><b className={classes.tagTxt}>{i?.name}</b></>)
                                                })}
                                            </> : 
                                            <>No Tags Found</>
                                        }

                                    </Typography>
                                    <Typography className="title-txts">
                                        <span>Total effort :</span>
                                        {AllTicketsById?.[ticketInfo?.id]?.totalEffort ? AllTicketsById?.[ticketInfo?.id]?.totalEffort : "-"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={4} className='bl-1'>
                                    <Typography className="title-txts">
                                        <span>Assets :</span>
                                    </Typography>
                                    <Box>
                                        {ticketInfo?.assets?.length === 0 && <><Typography className={classes.textWithSpanTitle}>No Assets Found</Typography></>}
                                        {ticketInfo?.assets?.map && ticketInfo?.assets?.map((i: any, index: number) => {
                                            return (
                                                <Typography className={classes.textWithSpanTitle}>
                                                    {index + 1}{")"}{i?.assetType?.displayName} - {i?.assetTag}
                                                </Typography>
                                            )
                                        })}
                                    </Box>
                                    <Typography className="title-txts pt-1">
                                        <span>Attachments :</span>
                                    </Typography>
                                    <Box>
                                    <Box className={`${classes.row} gap15 ${classes.flxWrap}`}>
                                        {ticketInfo?.attachmentDetails?.length === 0 && <><Typography className={classes.textWithSpanTitle}>No Attachments Found</Typography></>}
                                        {ticketInfo?.attachmentDetails?.map((i: any, index: number) => {
                                            return (
                                                <Box>
                                                    <Avatar alt="DOC" className="pointer" sizes='small' src={i?.attachmentPath} onClick={()=>setActiveImgIndex(index)} />
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                    </Box>

                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        }
    </>, [ticketInfo?.id, AllTicketsById?.[ticketInfo?.id]])
    return (<>
        {viewContent}
        {activeImgIndex>=0 && <PopupImageSlider
          closeHandler = {()=>setActiveImgIndex(-1)}
          activeImgIndex= {activeImgIndex}
          carouselArr= {ticketInfo?.attachmentDetails|| []}
        />}
    </>)
})

const useStyles = makeStyles(() =>
    createStyles({
        flxWrap: {
            flexWrap: "wrap",
        },
        tagTxt: {
            background: "#ddd",
            marginRight: "5px",
            width: "max-content",
            maxWidth: "100%",
            padding: "0px 6px",
            fontSize: "12px !important",
        },
        title: {
            flexGrow: 1,
        },
        primaryTxt: {
            color: "#000"//data.mode === "light" ? '#1261b4' : mode.text,
        },
        successTxt: {
            color: '#00b315'
        },
        dangerTxt: {
            color: 'red'
        },
        TicketTag: {
            "&::after": {
              background: "#fff"//mode.cardHeader,
            },
            "&::before": {
              background: "fff"//mode.cardHeader,
            },
            "&.whitebck": {
              "&::after": {
                background: `#fff`,
              },
              "&::before": {
                background: `#fff`,
              }
            }
        },
        ticketCardTags: {
            display: 'block',
            fontSize: '9px',
            padding: '5px 8px',
            background: "#ddd",//mode.solids,
            whiteSpace: 'nowrap',
            margin: '0 4px',
            color:"#000" //mode.text
        },
        tagBlue: {
            background: '#1261b4 !important',
            color: '#ffffff !important',
        },
        tagWarn: {
            background: '#f39732 !important',
            color: '#ffffff !important',
        },
        viewDtls : {
            background: "#f1f1f1",
            margin: 0,
            marginLeft: "5px !important",
            padding: "3px 10px",
            lineHeight: 1,
            borderRadius: "15px",
            "& a":{
              display: "flex !important",
              alignItems: "center",
              gap: "5px",
              color:"#000",
            },
            "& span":{
              fontSize: "10px",
              display: "block",
            }
        },
        row: {
            display: "flex"
        },
        alignItemsCenter:{
            alignItems: "center",
        },
        popupHolder: {
            position: "fixed",
            top: 0,
            left: 0,
            background: "#0005",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            zIndex: 1399,
            "& .modal-dialog": {
                display: "flex",
                height: '100%',
                justifyContent: "center",
                alignItems: "center",
            },
            "& .modal-header": {
                display: "flex",
                alignItems: "center",
                padding: "20px",
                justifyContent: "space-between",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px"
            },
            "& .modal-body": {
                maxHeight: "calc(100vh - 200px)",
                overflowY: "scroll",
                padding: "0px"
            },
            "& .modal-body > div": {
                margin: "0 !important",
                maxWidth: "calc(100% - 15px)"
            },
            "& .modal-content": {
                background: "#fff",
                width: "100%",
                maxWidth: "1000px",
                borderRadius: "5px",
                //padding: "20px",
                "& img.Contactprofile": {
                    width: "calc(100% - 10px)",
                },
                "& img": {
                    objectFit: "cover"
                }
            }
        },
        textWithSpanTitle: {
            fontSize:"12px",
            "& .title-txts": {
                lineHeight: '1.5',
                marginBottom: 10,
                fontSize: '13px',
                "& > span": {
                    display: 'block',
                    fontSize: '11px',
                    marginBottom: '2px',
                    lineHeight: '1',
                    color: "#5559"//mode.link,
                }
            }
        },
        txtWithLink: {
            fontSize: ".7em",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            "& span": {
                wordBreak: "break-all"
            },
            "& a": {
                fontSize: ".7em",
                textDecoration: "none",
                display: "inline-block",
                lineHeight: 1,
                "& svg": {
                    fontSize: "12px"
                }
            }
        },
        viewPopupTitle: {
            fontSize: 15,
            maxWidth: "255px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          },
          cardTcktCount: {
            padding: "5px 10px",
            background: "#ffa000",
            display: "inline",
            lineHeight: "1",
            fontSize: "12px",
            position: "relative",
            "& .dots": {
              position: "absolute",
              width: "8px",
              height: "8px",
              background: "#fff",//mode.cardHeader,
              left: "-5px",
              borderRadius: "50%",
              top: "50%",
              transform: "translateY(-50%)",
            },
            "& .dots.dots-right": {
              left: "unset",
              right: "-5px",
            },
            "& .dots.whiteBG": {
              background: "#fff" //mode.whiteSolid
            },
          },
    }))