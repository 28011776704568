import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { createStyles, makeStyles } from "@mui/styles";
import React, { createRef, useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import avatarImg from "../../assest/avatar.png";
import { FileUpload } from "../../redux/actions/FileUpload/FileUpload";
import {
  getMFADisable,
  getMFAEnable,
} from "../../redux/actions/FreeTrial/FreeTrial";
import {
  LoginActionWithOtp,
  storeLoginInfo,
} from "../../redux/actions/Login/Login";
import { ActionType } from "../../redux/actions/Snackbars";
import {
  changePasswordAction,
  getLoggedAgent,
  saveProfileImg,
  updateAgent
} from "../../redux/actions/userManagement/agents";
import { IState } from "../../redux/reducers/rootReducers";
import { fileUploadServiceForBloeStorage } from "../../services/fileUpload/FileUpload";
import { CustomTooltip } from "../Login/MFA";
import { ChangePasswordForm } from "../ServicesSSP/Profile/ProfileBanner";
import AppLoader from "../common/AppLoader";
import CommonStyles from "../common/CommonStyles";
import { TextBoxLatest } from "../common/TextBoxLatest/TextBoxLatest";
import CustomModalDialog from "../common/customDialog";
import SideDrawer from "../common/sideDrawer";
import coverImg from "./../../assest/coverImg.jpg";

const MyLicenses: React.FC = () => {
  const useStyles = CommonStyles;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [imgVal, setImgVal] = useState<any>("");
  const [drawerState, setDrawerState] = useState(false)
  const [loader, setloader] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [passwordErr,setPasswordErr] = useState("")
  const currRole = useSelector(
    (state: IState) => state?.roleReducer?.LOGGED_URS_ROLE
  );
  const isMFAEnabled = useSelector((state: IState) => state?.FeatureReducer?.featurePermissions?.MultiFactorAuthentication);
  const currAgent = useSelector(
    (state: IState) => state?.agentReducer?.loggedInAgent
  );
  const profileImg = useSelector(
    (state: IState) => state?.agentReducer?.saveProfile
  );
  const [agentInfo, setagentInfo] = useState({
    agentDescription: "",
    language: "",
    email: "",
    firstName: "",
    lastName: "",
    mobilePhone: "",
    userName: "",
    roles: "",
    agentAvatarId: "",
    agentAvatarPath: null,
  } as any);
  const [passwordChange, setpasswordChange] = useState({
    username: agentInfo.userName,
    currentPassword: "",
    password: "",
    confirmPassword: "",
  } as any);
  useEffect(() => {
    setagentInfo(currAgent);
    console.log("currAgent_Agent", currAgent);
    setImgVal(currAgent.agentAvatarPath)
    dispatch(saveProfileImg("profile", currAgent.agentAvatarPath));

  }, [currAgent])
  const updateProfileCB = (resCode: string, response: any) => {
    console.log("updateProfileCBupdateProfileCB", resCode, response);
    if (resCode !== "0") return;
    console.log("updateProfileCBupdateProfileCB", resCode, response);
    // dispatch(getProfileImage('profile',response.data.agentAvatarId));
    dispatch(saveProfileImg("profile", response.agentAvatarPath));
  }
  const OnCloseDrawer = () => {
    setDrawerState(false);
    setpasswordChange({
      username: agentInfo.userName,
      currentPassword: "",
      password: "",
      confirmPassword: "",
    });
  };
  const changePasswordCB = (resStatus:string,res?:any) =>{
    if(resStatus === "failure"){
        setPasswordErr(res || "");
        return;
    }
    OnCloseDrawer();
  }
  const changePassword = () => {
    setDrawerState(true);
  }
  const onPasswordSubmit = (data:any) =>{
    dispatch(changePasswordAction(currAgent?.id, {
      username: currAgent.userName,
      ...data
    }, changePasswordCB));
  }
  const uploadCB = (resStatus: string, res: any) => {
    setloader(false);
    console.log("UPLOAD-CB", res);
    if (resStatus === "0") {
      let { id, filePath } = res.data;
      let currAgentInfo = { ...currAgent };
      currAgentInfo.agentAvatarId = id;
      currAgentInfo.agentAvatarPath = filePath;
      dispatch(updateAgent(currAgentInfo, currAgentInfo.id, updateProfileCB));
      setagentInfo({
        ...currAgentInfo,
        agentAvatarId: id,
        agentAvatarPath: filePath,
      });
      setImgVal("");
    } else {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message:
            typeof res.data === "string"
              ? res.data
              : "Image Upload failed! Please try again.",
          severity: "error",
          open: true,
        },
      });
    }
  };
 
  const inputFileHandler = (file: any) => {
    console.log("inputFileHandler", file?.target?.files?.[0]);
    const reader = new FileReader();
    const filetemp = file?.target?.files?.[0];

    reader.onload = () => {
      console.log("reader.result", reader.result);
      setImgVal(reader.result);
    };

    if (filetemp) {
      reader.readAsDataURL(filetemp);
    }

    let payload = {
      ...currAgent,
      agentAvatarPath: file?.target?.files?.[0]?.name,
    };


    const updateProfileCallBack = (resCode: string, response: any) => {
      console.log("updateProfileCallBack", resCode, response, file?.target?.files?.[0]);
  
      fileUploadServiceForBloeStorage(
        response.agentAvatarPath,
        file?.target?.files?.[0],
        (isDone: any, res: any) => {
          if (isDone) {
            console.log("updateProfileCallBack file uploaded", res);
            reader.onload = () => {
              console.log("reader.result", reader.result);
              dispatch(saveProfileImg("profile",reader.result));
            };
            // navigate("/contact");
          } else {
            console.log("updateProfileCallBack file failed", res);
          }
        }
      );
    };

    dispatch(updateAgent(payload, currAgent.id, updateProfileCallBack));
  };
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  const [drawersState, setDrawersState] = useState<boolean>(false);
  const EditDetails = () => {
    setDrawersState(true);
  };
  const OnCloseDrawers = () => {
    setDrawersState(false);
  };

  const renderProfilePIcture = (currentImg: any, imageFromApi: any) => {
    if (currentImg) {
      return <img src={`${currentImg}`} alt="" height={80} />;
    } else if (imageFromApi) {
      return <img src={`${imageFromApi}`} alt="" height={80} />;
    }
    else{
      return <img src={`${avatarImg}`} alt="" height={80} />;
    }
  };
  return (
    <>
      <AppLoader enabled={loader} />
      <Box className={classes.profileBox}>
        <Box className={`${classes.rowMargin} agent-rowMargin`}>
          <Box>
            <Box>
              <Box className={classes.profileImgHolder}>
                <img src={coverImg} alt="cover Img" height={200} width="100%" />
                <Box className="profileInfo">
                  <Box className="profileImg">
                    <Box>
                      <Box className="profilePicHolder">
                        {renderProfilePIcture(
                          imgVal,
                          profileImg
                        )}
                        <input
                          type="file"
                          id="csvUpload"
                          accept=".jpg,.png"
                          onChange={inputFileHandler}
                          className="d-none"
                        />
                        <label htmlFor="csvUpload">
                          <span>Upload Profile Image</span>
                        </label>
                      </Box>
                    </Box>
                    <Box className="profileTxt">
                      <Typography
                        className={classes.themeColorTxt}
                        variant="h6"
                      >
                        {currAgent.userName} <br />
                      </Typography>
                      <Typography
                        className={classes.themeColorTxt}
                        variant="body2"
                      >
                        {currAgent.email}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="controls">
                  {isMFAEnabled &&<>
                      <Typography
                        className={`${classes.themeColorTxt} ${classes.row} ${classes.alignItemsCenter}`}
                        variant="body2"
                        display="block"
                        gutterBottom
                        onClick={openModal}
                      >
                        {currAgent.isTwoFactorEnabled ? "Disable" : "Enable"} MFA
                      </Typography>
                       <Typography
                       className={`${classes.themeColorTxt} ${classes.row} ${classes.alignItemsCenter}`}
                       variant="body2"
                       display="block"
                       gutterBottom
                     >
                       |
                     </Typography>
                     </>
                    }
                   
                    <Typography
                      className={`${classes.themeColorTxt} ${classes.row} ${classes.alignItemsCenter}`}
                      variant="body2"
                      display="block"
                      gutterBottom
                      onClick={changePassword}
                    >
                      <GppGoodOutlinedIcon />
                      Change Password
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <br />
              <Box>
                <Grid container spacing={0} className="agent-profile-basicInfo">
                  <Grid item xs={12}>
                    <Box>
                      <Typography
                        onClick={EditDetails}
                        className={`${classes.themeColorTxt} ${classes.row} ${classes.alignItemsCenter} agent-profile-editicon`}
                        variant="h6"
                      >
                          <span> Basic Info &nbsp; </span> <EditOutlinedIcon />
                      </Typography>
                      <Divider />
                      <br />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} className="agent-details">
                      <Grid item md={3}>
                        <LabelFieldBox
                          label={"First Name"}
                          value={agentInfo.firstName}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <LabelFieldBox
                          label={"Last Name"}
                          value={agentInfo.lastName}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <LabelFieldBox
                          label={"Mobile"}
                          value={agentInfo.mobilePhone}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <LabelFieldBox
                          label={"Email"}
                          value={agentInfo.email}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <LabelFieldBox
                          label={"Language"}
                          value={
                            agentInfo.language !== null
                              ? agentInfo.language
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item md={3}>
                        <LabelFieldBox label={"Role"} value={currRole?.name} />
                      </Grid>
                      <Grid item md={3}>
                        <LabelFieldBox
                          label={"Agent Description"}
                          value={
                            agentInfo.agentDescription !== null
                              ? agentInfo.agentDescription
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          <SideDrawer
            onClose={OnCloseDrawers}
            drawerState={drawersState}
            title="Edit Agent"
          >
            <Box className="px-2">
              <Editagentdetails
                onClose={OnCloseDrawers}
                agentInfo={agentInfo}
              />
            </Box>
          </SideDrawer>
        </Box>
        <SideDrawer
          onClose={OnCloseDrawer}
          drawerState={drawerState}
          title="Change Password"
        >
        <ChangePasswordForm isReset={false} passwordErr={passwordErr} onPasswordSubmit={onPasswordSubmit} onClose={OnCloseDrawer} />                               

        </SideDrawer>
        {modalOpen && (
          <CustomModalDialog
            title=""
            parentClasses="sml-popup"
            id="raiseTicketModalSSP"
            isOpen={modalOpen}
            onClose={closeModal}
            headerContent={
              <>
                <Typography variant="body2">
                  Enable Multi Factor Authentication
                </Typography>
              </>
            }
            bodyContent={
              <>
                <AddForm currAgent={currAgent} closeModal={closeModal} />
              </>
            }
          />
        )}
      </Box>
    </>
  );
};
interface iEditagentdetails {
  onClose: any;
  agentInfo: any;
}
const Editagentdetails = ({ onClose, agentInfo }: iEditagentdetails) => {
  const firstNameRef: any = createRef();
  const lastNameRef: any = createRef();
  const mobilePhoneRef: any = createRef();
  const languageRef: any = createRef();
  const dispatch = useDispatch();

  const [firstNameError, setFirstNameError] = React.useState(false);

  useEffect(() => {
    firstNameRef.current.value = agentInfo.firstName;
    lastNameRef.current.value = agentInfo.lastName;
    mobilePhoneRef.current.value = agentInfo.mobilePhone;
    languageRef.current.value = agentInfo.language;
  }, []);

  const updateProfileCB = (resCode: string, response: any) => {
    if (resCode !== "0") return;
    dispatch(getLoggedAgent());
    onClose();
  };

  const updateProfile = () => {
    if (!firstNameRef.current?.value) {
      setFirstNameError(true);
      return;
    }
    let request: any = {
      ...agentInfo,
      firstName: firstNameRef.current.value,
      lastName: lastNameRef.current.value,
      mobilePhone: mobilePhoneRef.current.value,
      language: languageRef.current.value,
    };
    console.log("requestrequest", request);
    // return;
    dispatch(updateAgent(request, agentInfo.id, updateProfileCB));
  };

  return (
    <>
      <Box className="pt-1">
        <TextBoxLatest
          multiline={false}
          isMandotary={true}
          placeholder={"Please Enter"}
          title={"First Name"}
          type={"text"}
          name={"FirstName"}
          ref={firstNameRef}
        />
        {firstNameError && (
          <p style={{ color: "red", fontSize: "13px", marginBottom: "0px" }}>
            Please enter your First Name*
          </p>
        )}
      </Box>
      <Box className="pt-1">
        <TextBoxLatest
          multiline={false}
          placeholder={"Please Enter"}
          title={"Last Name"}
          type={"text"}
          name={"LastName"}
          ref={lastNameRef}
        />
      </Box>
      <Box className="pt-1">
        <TextBoxLatest
          multiline={false}
          placeholder={"Please Enter"}
          title={"Mobile"}
          type={"number"}
          name={"Mobile"}
          ref={mobilePhoneRef}
        />
      </Box>
      <Box className="pt-1">
        <TextBoxLatest
          multiline={false}
          placeholder={"Please Enter"}
          title={"Language"}
          type={"text"}
          name={"Language"}
          ref={languageRef}
        />
      </Box>
      <Button
        className={"w-100 , mt-1"}
        variant="contained"
        onClick={updateProfile}
      >
        <SaveIcon style={{ marginRight: "5px" }} />
        Update
      </Button>
    </>
  );
};

export const AddForm = ({
  currAgent,
  closeModal,
}: {
  currAgent: any;
  closeModal: any;
}) => {
  const location = useLocation();
  const param = useParams();
  const classes = loginStyles();
  const [Copied, setCopied] = useState(false);
  const [ErrorMsg, setErrorMsg] = useState("");
  const [otp, setOtp] = useState("");
  const [imgUrl, setimgUrl] = useState("");
  const [setupKey, setSetupKey] = useState("");
  const [accountStatus, setAccountStatus] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const copyToClipboard = () => {
    const textarea = document.createElement("textarea");
    textarea.value = `${setupKey}`;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopied(true);
  };
  const onSubmit = () => {
    if (otp.length < 6) {
      setErrorMsg("* Please enter 6 digit of OTP to proceed!");
      return;
    }
    if (!currAgent.isTwoFactorEnabled) {
      dispatch(LoginActionWithOtp(otp, onSubmitCB, param?.val));
    } else {
      dispatch(getMFADisable(otp, MFACB, param.val));
      closeModal();
    }
  };
  const onSubmitCB = (resStatus: string, res: any) => {
    console.log("props", res);
    if (resStatus === "0") {
      dispatch(storeLoginInfo(res));
      closeModal();
    } else {
      setErrorMsg("* Entered OTP is invalid!");
    }
  };
  useEffect(() => {
    dispatch(getMFAEnable(MFACB, param.val));
  }, []);
  const MFACB = (resStatus: string, res: any) => {
    console.log("asssss", res);
    if (resStatus === "0") {
      setimgUrl(`${res?.qrCodeImageUrl}`);
      setSetupKey(`${res.manualKey}`);
    }
  };
  useEffect(() => {
    if (param?.val) return;
    const accountStatus = new URLSearchParams(location.search).get(
      "accountStatus"
    );
    console.log("param?.val", accountStatus);
    if (accountStatus === "New") {
      dispatch(getMFAEnable(MFACB, param.val));
    }
  }, []);
  return (
    <>
      <Box>
        <Container maxWidth="lg" className="LoginContainer">
          <Box className="loginForm">
            <Box className="formHolder">
              <Box>
                <Typography variant="h5" gutterBottom className="text-center">
                  Enter OTP
                </Typography>
                {!currAgent.isTwoFactorSetupCompleted && (
                  <Box className="text-center">
                    <img src={imgUrl} alt="" height={160} />
                    <Typography
                      variant="body2"
                      className={`${classes.noteTxt}`}
                    >
                      Setup Key : {setupKey}
                      <CustomTooltip
                        className={`pointer ${Copied ? "Copied" : ""}`}
                        title={Copied ? "Copied" : "Copy Code"}
                      >
                        <ContentCopyIcon
                          sx={{ color: `${Copied ? "green" : ""}` }}
                          onClick={copyToClipboard}
                        />
                      </CustomTooltip>
                    </Typography>
                    <br />
                  </Box>
                )}
                <OTPInput
                  value={otp}
                  onChange={(e: any) => {
                    setErrorMsg("");
                    setOtp(e);
                  }}
                  numInputs={6}
                  inputType="number"
                  placeholder="______"
                  renderSeparator={<span></span>}
                  renderInput={(props) => (
                    <input {...props} className="otp-box" />
                  )}
                />
                <br />
              </Box>
              <Box>
                {ErrorMsg !== "" && (
                  <Typography
                    variant="body2"
                    className="txt-danger text-center"
                    gutterBottom
                  >
                    {ErrorMsg}
                  </Typography>
                )}
              </Box>
              <Box className="text-center">
                <Button
                  className="btnSubmit"
                  onClick={onSubmit}
                  variant="contained"
                >
                  Submit
                </Button>
              </Box>
              <Box>
                <Typography variant="body2" className="text-center">
                  <span
                    className="pointer"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    Cancel{" "}
                  </span>
                </Typography>
              </Box>
              <Box>
                {/* <Typography variant="body2" className={classes.noteTxt}>  
                  Please check your <br/> authenticator app for the OTP!
                </Typography> */}
                {!currAgent.isTwoFactorSetupCompleted && (
                  <CustomTooltip
                    title={
                      <Box>
                        <Typography
                          variant="body2"
                          className={`${classes.noteTxt} pointer`}
                        >
                          Step 1 : Install Authenticator app.
                        </Typography>
                        <Typography
                          variant="body2"
                          className={`${classes.noteTxt} pointer`}
                        >
                          Step 2 : Login with your email address.
                        </Typography>
                        <Typography
                          variant="body2"
                          className={`${classes.noteTxt} pointer`}
                        >
                          Step 3 : Use the above QR code or Setup Key to
                          generate the OTP.
                        </Typography>
                        <Typography
                          variant="body2"
                          className={`${classes.noteTxt} pointer`}
                        >
                          Step 4 : Enter the OTP and Submit!
                        </Typography>
                      </Box>
                    }
                  >
                    <Typography
                      variant="body2"
                      className={`${classes.noteTxt} text-center pointer`}
                    >
                      Need Help?
                    </Typography>
                  </CustomTooltip>
                )}
              </Box>
            </Box>
          </Box>
          <Box>
            <br />
            <br />
            <br />
          </Box>
        </Container>
      </Box>
    </>
  );
};
export const loginStyles = makeStyles(() =>
  createStyles({
    noteTxt: {
      padding: "10px",
      borderRadius: "8px",
      fontSize: 10,
      color: "#555",
      "& svg": {
        height: 12,
        width: 12,
        marginLeft: 5,
        cursor: "pointer",
      },
    },
  })
);
interface iLabelFieldBoxProps {
  label: string;
  value: string | null;
  className?: string;
}
export const LabelFieldBox = ({
  label,
  value = "-",
  className = "",
}: iLabelFieldBoxProps) => {
  return (
    <Box className={`LabelFieldBox ${className}`}>
      <Typography className="label">{label}</Typography>
      <Typography>{value || "-"}</Typography>
    </Box>
  );
};
export default MyLicenses;
