import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { IState } from '../../../redux/reducers/rootReducers';
import { FallbackAvatar } from '../../MobileComponents/MobileUtilities/ContactView';
import { ConvertZ } from '../../helpers/helperFunctions';
import CustomFormLabel from '../../common/form-label/CustomFormLabel ';
import CustomSelect from '../../common/customSelect/CustomSelect';
import { useState } from 'react';
import { debounce } from 'lodash';
import { ShowMorefun } from '../../common/CustomTimeLine';
const getAssigneeName = (manager: any, agent: any) => {
    if (manager?.id) {
        return manager?.knownAs;
    } else if (agent?.id) {
        return agent?.firstName ? `${agent?.firstName} ${agent?.lastName}` : "Unassigned";
    } else {
        return "";
    }
}
const TaskItem = (props: any) => {
    const { taskStatuses = [],currAgent = {}, callback, hideTitle, isChosen, taskInfo, id } = props;
    const { title, managerId, manager, agentId, description="-", agent, ticketStatus, dueDateTime } = taskInfo?.ticketTaskLogs;
    const [statusVal,setStatusVal] = useState(ticketStatus ? {...ticketStatus,label:ticketStatus?.displayName,value:ticketStatus?.id} : null)
    const taskUpdate = debounce(() =>{
        callback(statusVal,taskInfo)
    },300)
    return (<>
        <Box className={`task-item white-bg ${isChosen ? "active" : ""}`}>
            <Box className="task-view-row  m-0">
                <Box className="task-header">
                    <Typography variant="body2" className={`AssetsTcktsList id`} > #T{id}&nbsp; &nbsp; </Typography>
                    <Typography className={`badge`}><span >{ticketStatus?.displayName || "Unassigned"}</span></Typography>
                </Box>
                <Box className="task-body">
                    {!hideTitle && <ShowMorefun  textData={description} />}
                    {/* <Typography variant="body2" className={`pre-wrap-txt`} >
                        <span>{!hideTitle && <>{description}</>}</span>
                    </Typography> */}
                </Box>
            </Box>
            <hr/>
            <Box className="task-footer">
                <Box>
                    <Box className="sla-holder">
                        <Box className="assignee">
                            <FallbackAvatar
                                src={``}
                                alt={getAssigneeName(manager, agent)}
                                fallback={`${getAssigneeName(manager, agent)}`}
                                width={25}
                                height={25}
                            />
                            <Typography variant="body2" className={`AssetsTcktsList`} >
                                {managerId > 0 &&
                                    <span>
                                        {`${manager?.userName || "Unassigned"}`} (Contact)
                                    </span>
                                }
                                {agentId > 0 &&
                                    <span>
                                        {agent?.firstName ? `${agent?.firstName} ${agent?.lastName}` : "Unassigned"} (Agent)
                                    </span>
                                }
                            </Typography>
                        </Box>
                        <Typography >Due Date : <b>{`${ConvertZ(dueDateTime)}`}</b></Typography>
                        <Box className="action">
                            {(currAgent?.id === agent?.id) && <>
                                    <Box>
                                        <CustomFormLabel
                                            labelName={"Status"}
                                            isMandotary={true}
                                        />
                                        <CustomSelect
                                            name="ticketType"
                                            defaultValue={statusVal}
                                            options={taskStatuses}
                                            handleChange={(e: any) => {
                                                setStatusVal(e);
                                            } }
                                            customClassNames="sml-txt-dropdown" 
                                            isMulti={false} 
                                            isDisabled={false} 
                                            isColor={false} 
                                            width={undefined} 
                                            placeholder={"Please select"} 
                                            isError={undefined} 
                                            isOpen={false}                        
                                        />
                                    </Box>
                                    <Box>
                                        <Button 
                                            variant='contained'
                                            disabled={(statusVal === null || ticketStatus?.id === statusVal?.id ) ? true : false } onClick={taskUpdate}
                                        >Update</Button>
                                    </Box>
                            </>}
                        </Box>
                    </Box>
                </Box>
            </Box>

        </Box>
    </>)
}

// export default TaskItem

const mapStateToProps = (state: IState) => {
    return ({
        currAgent: state?.agentReducer?.loggedInAgent,
        taskStatuses: state?.TicketsReducer?.allResponses?.ticketTaskStatus
    })
};



export default connect(mapStateToProps)(TaskItem);



 {/* {(getTaskAccess(taskInfo)) && <>
    {(taskInfo?.workflowNodeId && taskInfo?.workflowNodeId > 0) ?
        <Typography className="task-actions">
            <>
                <Tooltip title="Approve">
                    <span style={{ cursor: "pointer", color: "red" }} onClick={() => { rejectTask(i) }}><CloseOutlinedIcon /> Reject</span>
                </Tooltip>
                <Tooltip title="Approve">
                    <span style={{ cursor: "pointer", color: "green" }} onClick={() => { taskApprove(i) }}> &nbsp;<DoneOutlinedIcon /> Approve</span>
                </Tooltip>
            </>
        </Typography>
        :
        <Typography className="task-actions">
            <>
                <Tooltip title="Complete">
                    <span style={{ cursor: "pointer", color: "green" }} onClick={() => { taskComplete(i) }}> &nbsp;
        <DoneOutlinedIcon /> Complete
                    </span>
                </Tooltip>
            </>
        </Typography>
    }</>} */}



// const getTaskAccess = (item: any) => {
//     let valid = false;
//     const { statusId, agentId, managerId } = item;
//     const { Approve, Reject, Complete } = AllInfo?.statusTypes
//     if (currAgent?.id === agentId && managerId === 0) {
//         if (Approve !== statusId && Reject !== statusId && Complete !== statusId) {
//             valid = true
//         }
//     }
//     return valid;
// }

// const taskApprove = (taskObj: any) => {
//     if (!AllInfo?.statusTypes?.Approve) {
//         dispatch(triggerAppAlert(`User doesn't have Role Permission to approve! Please Contact Admin.`, 'warning'))
//         return;
//     }
//     const payload = { ...taskObj }
//     if (payload.ticketTaskLogs) {
//         payload.ticketTaskLogs.statusId = AllInfo?.statusTypes?.Approve;
//         payload.ticketTaskLogs.ticketStatus = AllInfo.statusTypesById[AllInfo?.statusTypes?.Approve];
//     }
//     dispatch(updateTicketTask(payload, taskApproveCB))//taskObj
// }
// const taskComplete = (taskObj: any) => {
//     if (!AllInfo?.statusTypes?.Complete) {
//         dispatch(triggerAppAlert(`User doesn't have Role Permission to Complete! Please Contact Admin.`, 'warning'))
//         return;
//     }
//     const payload = { ...taskObj }
//     if (payload.ticketTaskLogs) {
//         payload.ticketTaskLogs.statusId = AllInfo?.statusTypes?.Complete;
//         payload.ticketTaskLogs.ticketStatus = AllInfo.statusTypesById[AllInfo?.statusTypes?.Complete];
//     }
//     dispatch(updateTicketTask(payload, taskApproveCB))//taskObj
// }
// const rejectTask = (taskObj: any) => {
//     if (!AllInfo?.statusTypes?.Reject) {
//         dispatch(triggerAppAlert(`User doesn't have Role Permission to Reject! Please Contact Admin.`, 'warning'))
//         return;
//     }
//     const payload = { ...taskObj }
//     if (payload.ticketTaskLogs) {
//         payload.ticketTaskLogs.statusId = AllInfo?.statusTypes?.Reject;
//         payload.ticketTaskLogs.ticketStatus = AllInfo.statusTypesById[AllInfo?.statusTypes?.Reject];
//     }
//     dispatch(updateTicketTask(payload, taskApproveCB))//taskObj
// }
// const taskApproveCB = (resStatus: string, response: any) => {
//     console.log('taskApproveCB', resStatus, response)
//     dispatch(triggerAppAlert(`Task ${response?.id} updated successfully!`, 'warning'))
//     callback(response)
// }