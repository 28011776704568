import axios from "axios";
import configs from "../../../configs/config";
import { getLoggedAgent, updateAgent } from "./../userManagement/agents";
import { ActionType, snackbar } from "../Snackbars";
import { storePermissions } from "../SystemManagement/Permission";
import { LoggedInUsrRole } from "../userManagement/role";
import { ContactByTokenAction } from "../ContactManagement/Contact";
import { useMsal } from "@azure/msal-react";
import { theme } from "../Theme/Theme";
import { useNavigate } from "react-router-dom";
import { alertMessage } from "../SystemIcons/systemIcons";
const getSSOCredentialsUrl = `${configs.BASE_URL}/common/sso`;
const getTenantBySubDomain = `${configs.BASE_URL}/master/Organisation/by/subdomain?subdomain=`;
const OrganisationUrl = `${configs.BASE_URL}/master/organisation`;
const ContactUrl = `${configs.BASE_URL}/user/Contact/login`;
const ForgetPasswordURL = `${configs.BASE_URL}/UserManagement/forget/password/sendmail`;
const ForgetUsernameURL = `${configs.BASE_URL}/common/Login/forgot/username?email`;
const url = `${configs.BASE_URL}/Agent/login`;
const commonLoginUrl = `${configs.BASE_URL}/common/Login`;
const getOrgByUID = `${configs.BASE_URL}/master/Organisation/by/username?userName=`;
const getAgentUrl = `${configs.BASE_URL}/Agent/by/token`;
const getOrgByUemailId = `${configs.BASE_URL}/master/Organisation/by/email?email=`;
const getAllSSOConfig = `${configs.BASE_URL}/common/SSO`;
const bulkUpdate = `${configs.BASE_URL}/master/SystemException/permanent/delete?ids=`;
const versionApiUrl = `${configs.BASE_URL_VERSIONING}`;
export const GET_LOGIN = "GET_LOGIN";
export const GET_SUBMITTED = "GET_SUBMITTED";
export const SET_SUBMITTED = "SET_SUBMITTED";
export const ssoToggle = (status: any) => ({
  type: "SSO_STATUS",
  payload: status,
});
export const getStoreTicketsData = (data: any) => ({
  type: "STORE_DASHBOARD_DATA",
  payload: data,
});
export const getStoreLogin = (Id: any) => ({
  type: GET_LOGIN,
  payload: Id,
});
export const setTheme = (Id: any) => ({
  type: SET_SUBMITTED,
  payload: Id,
});
export const isLoggedInAction = (Id: any) => ({
  type: GET_SUBMITTED,
  payload: Id,
});
export const isLoginAction = (status: boolean) => ({
  type: "LOGIN_CALL",
  payload: status,
});
export const storeRoleAccess = (value: any, roleType: string) => ({
  type: roleType,
  payload: value,
});
export const StoreAccessData = (val: any, roleType = "STORE_ROLE_ACCESS") => {
  return async (dispatch: any) => {
    dispatch(storeRoleAccess(val, roleType));
  };
};
export const StoreDashboardData = (val: any) => {
  return async (dispatch: any) => {
    console.log("StoreDashboardData", val);
    dispatch(getStoreTicketsData(val));
  };
};
export const DoLogOutAction = () => {
  return async (dispatch: any) => {
    localStorage.clear();
    dispatch(theme(false));
    dispatch(isLoggedInAction(""));
    dispatch(isLoginAction(false));
    dispatch(StoreAccessData(false));
    dispatch(storePermissions(false));
    dispatch({
      type: "LOGGEDIN_USR_ROLE",
      payload: false,
    });
    dispatch({
      type: "LOGGED_IN_AGENT",
      payload: false,
    });
    dispatch({
      type: "UNSET_FILTERS",
      payload: false,
    });
    dispatch({
      type: "saveOrgProfile",
      payload: false,
    });
    dispatch({
      type: "saveOrgProfile",
      payload: false,
    });
    window.location.reload()
  };
};

export const commonLogoutAction = async (isContact:boolean) => {
  let url = isContact ? "/user/contact/logout" : "/agent/logout"
  try {
      let result = await axios.post(`${url}`);
      return result.data;
  } catch (e) {
      console.log(e)
      return false
  }
}
export const LoginActionWithOtp = (otp: any, callback?: any, token?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${url}/${otp}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (callback) callback("0", response.data);
    } catch (e: any) {
      if (callback) callback("1", e?.response);
      console.log(e?.response);
    }
  };
};
export const LoginAction = (
  data: any,
  callback?: any,
  organisation: string = "organisation"
) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          "tenant-code": organisation,
        },
      });
      localStorage.setItem("tenantCode", organisation);
      if (response?.data?.isTwoFactorEnabled) {
        if (callback) callback("MFA", response?.data);
      } else {
        dispatch(storeLoginInfo(response.data));
      }
    } catch (e: any) {
      if (callback) callback("-1");
      console.log(e?.response);
    }
  };
};
export const SSOLoginWithToken = (email: string | null = "", ssoAccessToken: string | null = "", localAccountId: string | null, callback: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: "LOGIN_LOADER", payload: true })
      let userTenantCode = localStorage.getItem("tenantCode")
      const loginResponse: any = await axios.post(`${commonLoginUrl}/sso`, {
        "mailId": email,
        "ssoAccessToken": ssoAccessToken,
        "azureADID": localAccountId
      }, {
        headers: {
          "tenant-code": userTenantCode,
          "Authorization": `Bearer ${ssoAccessToken}`,
        },
      });
      console.log('SSOLoginWithToken_response', loginResponse)
      dispatch(proceedLoginWithToken(loginResponse, callback, `${userTenantCode}`))
      dispatch({ type: "LOGIN_LOADER", payload: false })
    } catch (e: any) {
      dispatch({ type: "LOGIN_LOADER", payload: false })
      console.log("SSOLoginWithToken", e?.response);
      dispatch(ssoToggle(null))
      // callback();
      dispatch({
        type: "SET_LOGIN_ERROR",
        payload: "SSOErr"
      })
      localStorage.clear();
    }
  };
};
export const getOrganisationByUserNameAction = (data: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await axios(`${getOrgByUID}${data.username}`);
      console.log("loginResponse1", response);
      dispatch(CommonLoginAction(data, callback, response?.data?.tenantcode));
    } catch (e: any) {
      if (callback) callback("-1");
      console.log(e?.response);
    }
  };
};
export const loginWithSubDomain = (subdomain: string, data: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${getTenantBySubDomain}${subdomain}`);
      // const response: any = await axios(`${getOrgByUID}${data.username}`);
      console.log("loginResponse1", response);
      dispatch(CommonLoginAction(data, callback, response?.data?.tenantcode));
    } catch (e: any) {
      if (callback) callback("-1");
      console.log(e?.response);
    }
  };
};
export const getTenantCodeBySubDomain = (subdomain:string, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${getTenantBySubDomain}${subdomain}`);
      // const response: any = await axios(`${getOrgByUID}${data.username}`);
      if (callback) callback("0", response.data);
    } catch (e: any) {
      if (callback) callback("-1");
      console.log(e?.response);
    }
  };
};
// export const CommonLoginAction = (
//   data: any,
//   callback?: any,
//   organisation: string = "organisation"
// ) => {
//   return async (dispatch: any) => {
//     try {
//       const response: any = await axios.post(commonLoginUrl, data, {
//         headers: {
//           "tenant-code": organisation,
//         },
//       });
//       console.log("loginResponse", response.data, response?.data?.userType);
//       localStorage.setItem("tenantCode", organisation);
//       let userType = response?.data?.userType;
//       if (userType === "Agent") {
//         if (response?.data?.isTwoFactorEnabled) {
//           if (callback) callback("MFA", response?.data);
//         } else {
//           dispatch(storeLoginInfo(response.data));
//         }
//       } else if (userType === "Contact") {
//         localStorage.setItem("tenantCode", organisation);
//         localStorage.setItem(
//           "loginInfo",
//           JSON.stringify({ ...response.data, isContact: true })
//         );
//         setTimeout(() => {
//           dispatch(ContactByTokenAction());
//         }, 10);
//         console.log("contact-Login-response", response);
//       }
//     } catch (e: any) {
//       if (callback) callback("-1");
//       console.log(e?.response);
//     }
//   };
// };
export const CommonLoginAction = (
  data: any,
  callback?: any,
  organisation: string = ""
) => {
  return async (dispatch: any) => {
    try {
      const response: any = await axios.post(commonLoginUrl, data, {
        headers: {
          "tenant-code": organisation,
          "app-type": "Web"
        },
      });
      console.log("loginResponse", response.data, response?.data?.userType);
      localStorage.setItem("tenantCode", organisation);
      let userType = response?.data?.userType;
      localStorage.setItem("userType", userType);
      if (userType === "Agent") {
        localStorage.setItem("loginInfo", JSON.stringify({...response?.data,isAgentLogin:true}));
        setTimeout(() => {
            if (response?.data?.isTwoFactorEnabled) {
              if (callback) callback("MFA", response?.data);
            } else  if (response?.data?.accountStatus === "New") {
                if (callback) callback("accountStatus", response?.data);
            }else{
              dispatch(storeLoginInfo(response.data));
            }  
        }, 500);
        
      } else if (userType === "Contact") {
        localStorage.setItem(
          "loginInfo",
          JSON.stringify({ ...response.data, isContact: true,isContactLogin:true })
        );
        setTimeout(() => {
          dispatch(ContactByTokenAction());
        }, 10);
        console.log("contact-Login-response", response);
      }
    } catch (e: any) {
      if (callback) callback("-1");
      console.log(e?.response);
    }
  };
};

export const proceedLoginWithToken = (
  response: any,
  callback?: any,
  organisation: string = "organisation"
) => {
  return async (dispatch: any) => {
    try {
      localStorage.setItem("tenantCode", organisation);
      let userType = response?.data?.userType;
      localStorage.setItem("userType", userType);
      if (userType === "Agent") {
        localStorage.setItem("loginInfo", JSON.stringify({...response?.data,isAgentLogin:true}));
        if (response?.data?.isTwoFactorEnabled) {
          if (callback) callback("MFA", response?.data);
        } else {
          dispatch(storeLoginInfo(response.data));
        }
      } else if (userType === "Contact") {
        localStorage.setItem("loginInfo", JSON.stringify({ ...response.data, isContact: true,isContactLogin:true }));
        setTimeout(() => {dispatch(ContactByTokenAction())}, 100);
      }
    } catch (e: any) {
      if (callback) callback("-1");
      console.log(e?.response);
    }
  };
};

export const LoginActionForAdminPortal = (
  data: any,
  callback?: any,
  type: string = "admin"
) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(
        `${configs.BASE_URL}/Admin/login`,
        data
      );
      console.log("LoginActionForAdminPortal", response?.data);
      if (response?.data) {
        localStorage.setItem(
          "loginInfo",
          JSON.stringify({ ...response.data, isAdminLogin: true })
        );
        setTimeout(() => {
          dispatch(isLoginAction(true));
        }, 10);
      }
    } catch (e: any) {
      dispatch({
        type: "SET_LOGIN_ERROR",
        payload: true
      })
      if (callback) callback("-1");
      console.log(e?.response);
    }
  };
};
export const storeLoginInfo = (token: any) => {
  return async (dispatch: any) => {
    try {
      setTimeout(() => {
        dispatch(getLoggedAgent());
      }, 100);
    } catch (e: any) {
      console.log(e);
    }
  };
};
export const contactLogin = (
  data: any,
  callback?: any,
  organisation: string = "organisation"
) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(ContactUrl, data, {
        headers: {
          "tenant-code": organisation,
        },
      });
      localStorage.setItem("tenantCode", organisation);
      localStorage.setItem(
        "loginInfo",
        JSON.stringify({ ...response.data, isContact: true,isContactLogin:true })
      );
      setTimeout(() => {
        dispatch(ContactByTokenAction());
      }, 10);
      console.log("contact-Login-response", response);
    } catch (e: any) {
      if (callback) callback("-1");
      console.log("login err", e);
    }
  };
};

export const getSSOCredentials = (subdomain: string, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${getTenantBySubDomain}${subdomain}`);
      localStorage.setItem('tenantCode', response?.data?.tenantcode)
      const credentials = await axios(`${getSSOCredentialsUrl}`);
      console.log("credentialscredentials", credentials.data)
      try {
        if (callback && credentials?.data) callback("0", credentials.data);
      } catch (e) { }
    } catch (e: any) {
      if (callback) callback("1", e);
      let msg = e?.response?.data;
      if (msg && typeof msg === "string") {
        dispatch(alertMessage(msg, "error"))
      }
      console.log("login err", e?.response);
    }
  };
};
export const getOrganisations = (callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(OrganisationUrl);
      try {
        if (callback) callback("0", response.data);
      } catch (e) { }
    } catch (e: any) {
      if (callback) callback("1", e);
      console.log("login err", e);
    }
  };
};

export const getAgentsByOrg = async (id: any) => {
  try {
    let result = await axios.get(
      `${configs.BASE_URL}/Admin/${id}`
    );
    return result.data;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getAOrgInfo = async (id: any) => {
  try {
    let result = await axios.get(
      `${configs.BASE_URL}/master/Organisation/info/${id}`
    );
    return result.data;
  } catch (e) {
    console.log(e);
    return false;
  }
};
export const renewLicense = async (orgId: number, lstId: string) => {
  try {
    let result = await axios.get(
      `${configs.BASE_URL}/master/Organisation/renew/${orgId}?subscriptionType=${lstId}`
    );
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getVersioning = async () => {
  try {
        // Get the domain and build the URL
        let versionUrl;

        if (window.location.hostname === 'localhost') {
          // If running on localhost, use a local file or dev URL
          versionUrl = '/app-version.json';
        } else {
          // For production, use the production URL
          const domain = window.location.origin;
          versionUrl = `${domain}/app-version.json`;
        }

      let result = await axios.get(versionUrl);
      return result;
  } catch (e) {
      console.log(e)
      return false
  }
}


export const upgradeLicense = async (
  orgId: number,
  noOfUsers: any,
  editionId: any
) => {
  try {
    let result = await axios.get(
      `${configs.BASE_URL}/master/Organisation/upgrade/${orgId}?NumberOfUsers=${noOfUsers}&editionId=${editionId}`
    );
    console.log("upgradeLicense", result);

    if (result) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getSystemExceptionList = async (
  count?: any,
  page?: any,
  organisationName?: any,
) => {

  try {
    let result = await axios.get(
      `${configs.BASE_URL}/master/SystemException?tenantcode=${organisationName}&page=${page}&count=${count}`
    );
    console.log("getSystemExceptionList", result);
    if (result) {
      return result.data;
    } else {
      return false;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const deleteSystemExceptionList = (id: number, callback: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${bulkUpdate}${id}`);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `Deleted Successfully!`,
          severity: "success",
          open: true,
        },
      });
    callback(true)
    } catch (error: any) {
      console.error('Error deleting system exception:', error);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `Error deleting: ${error.message}`,
          severity: "error",
          open: true,
        },
      });
      callback(false)
    }
  };
};

export const updateEditionInfo = async (payload: any, id: any) => {
  try {
    let result = await axios.put(
      `${configs.BASE_URL}/Edition/${id}`, payload, {
      headers: {
        "Content-Type": "application/json",
      }
    }
    );
    console.log("upgradeLicense", result);
    if (result) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};
export const getOrganisationByUserNameActionForgotPassword = (data: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await axios(`${getOrgByUID}${data.username}`);
      console.log("loginResponse1", response);
      dispatch(ForgetPassword(data, response?.data?.tenantcode, callback));
    } catch (e: any) {
      if (callback) callback("-1");
      console.log(e?.response);
    }
  };
};
export const ForgetPassword = (data: any, Org?: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(ForgetPasswordURL, data, {
        headers: {
          "Tenant-Code": Org,
        },
      });
      callback("0", response.data);
    } catch (error: any) {
      if (error.response) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message:
              error?.response?.data && typeof error.response.data === "string"
                ? error.response.data
                : "Something went wrong!",
            severity: "error",
            open: true,
          },
        });
        console.log("error=>", error.response.data);
      }
    }
  };
};
export const ForgetUsername = (data: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${ForgetUsernameURL}=${data}`);
      callback("0", response.data);
    } catch (error: any) {
      if (error.response) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message:
              error?.response?.data && typeof error.response.data === "string"
                ? error.response.data
                : "Something went wrong!",
            severity: "error",
            open: true,
          },
        });
        console.log("error=>", error.response.data);
      }
    }
  };
};
export const SSOLoginWithEmail = (email: string | null = "", callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await axios(`${getOrgByUemailId}${email}`)
      if (response?.data?.tenantcode) {
        //response?.data?.tenantcode
        localStorage.setItem("tenantCode", response?.data?.tenantcode);
      }
      //const loginResponse = await axios(`${commonLoginUrl}/sso`)
      const loginResponse: any = await axios(`${getAllSSOConfig}`,
        {
          headers: {
            "tenant-code": response?.data?.tenantcode,
          },
        });
      console.log("loginResponse", loginResponse);
      const filtered = loginResponse.data.filter((ele: any) => ele.isActive !== false)
      console.log(filtered);

      callback(filtered[0])
    } catch (e: any) {
      console.log("SSOLoginWithToken", e?.response);

    }
  };
};
export default LoginAction;
