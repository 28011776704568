import SimpleAccordion from "../../../components/common/accordion/SimpleAccordion";
import { ReactComponent as FallBackImgForContact } from "../../../assest/avatar/avatar.svg";
import {
  CommonDataForFormDataProperties,
  NullableArgument,
  iMemoizedComponent,
  iMemoizedComponentForGenerateContactChip,
} from "./Type";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../redux/reducers/rootReducers";
import { useEffect, useMemo, useState } from "react";
import { downloadMultiFilesById } from "../../../redux/actions/TicketsManagement/TicketsManagement";
import { Avatar, AvatarGroup, Popover, Typography } from "@mui/material";
import ContactsIcon from "@mui/icons-material/Contacts";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import CustomPopover from "./Popover/Popover";
import CustomChip from "../../../components/common/chip/CustomChip";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import WorkIcon from "@mui/icons-material/Work";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import BadgeIcon from "@mui/icons-material/Badge";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import IconButton from "@mui/material/IconButton";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import Slider from "react-slick";
import { Box } from "@mui/system";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import AttachmentIcon from "@mui/icons-material/Attachment";
import FilterEmail from "../../edit-ticket/filterEmail/FilterEmail";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";

export function getMimeType(base64String: any) {
  const mimeTypeRegex = /^data:(.+);base64/;
  const matches = mimeTypeRegex.exec(base64String);
  let mimeType = "";
  if (matches) {
    mimeType = matches[1];
  }

  return mimeType;
}
export function getMimeTypeFromUrl(url: string) {
  try {
    // Extract file extension from URL
    const extensionRegex = /\.([0-9a-z]+)(?:[\?#]|$)/i;
    const matches = extensionRegex.exec(url);
    let fileExtension = null;
    if (matches && matches.length > 1) {
      fileExtension = matches[1];
    }

    // Determine type based on file extension
    let type = "unknown";
    if (fileExtension) {
      switch (fileExtension.toLowerCase()) {
        case "png":
        case "jpg":
        case "jpeg":
          type = "image/png";
          break;
        case "pdf":
          type = "application/pdf";
          break;
        case "docx":
          type =
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          break;
        default:
          type = "unknown";
          break;
      }
    }

    return { fileExtension, type };
  } catch (error) {
    console.error("Error:", error);
    return { fileExtension: null, type: "unknown" };
  }
}

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const generateContacImg = (
  allContactImgUrls: any,
  contact: any,
  width: number,
  type?: string
) => {
  if (contact && allContactImgUrls?.contactsImg) {
    if (allContactImgUrls?.contactsImg[contact?.profilePictureId]) {
      return (
        <img
          className="accord-profile-img"
          src={allContactImgUrls.contactsImg[contact.profilePictureId]}
          width={width}
          height={width}
          alt=""
        />
      );
    } else {
      return (
        <FallBackImgForContact
          style={{
            width: width,
            height: width,
            border: "1px solid gray",
            borderRadius: "50%",
          }}
        />
      );
    }
  }
};

const generateContactImgGroup = (
  primaryContact: any,
  secondaryContact: any,
  allContactImgUrls: any
) => {
  if (allContactImgUrls) {
    if (primaryContact || secondaryContact) {
      return (
        <AvatarGroup spacing={5}>
          {generateContacImg(allContactImgUrls, primaryContact, 24, "primary")}

          {generateContacImg(allContactImgUrls, secondaryContact, 24, "second")}
        </AvatarGroup>
      );
    }
  }
};

const generateContactAlertIcon = (alert: string, style: any) => {
  if (alert) {
    return (
      <div className={style.contactChipAlertIconContainer}>
        <CustomPopover
          popupContent={
            <>
              <div className={style.contactChipAlertPopoverTextContainer}>
                {alert}
              </div>
            </>
          }
        >
          <ReportProblemOutlinedIcon fontSize="small" color="warning" />
        </CustomPopover>
      </div>
    );
  }
};

const generateContactName = (name: string, isVip: boolean, style: any) => {
  const generateVipChip = () => {
    if (isVip) {
      return (
        <CustomChip
          icon={
            <DiamondOutlinedIcon
              fontSize="small"
              color="primary"
              width="15px"
            />
          }
          label="VIP"
          color="#2F80ED"
          bg="#e3efff"
          variant={"none"}
          transform={"scale(0.7)"}
        />
      );
    }
  };

  if (name) {
    return (
      <span className={style.contactChipNameContainer}>
        {name}
        {generateVipChip()}
      </span>
    );
  }
};

const generateAssetName = (assetList: any, assetId: any) => {
  console.log("assetListassetList", assetList, assetId);
  let assetTemp = assetList ? assetList?.find((x: any) => x.id == assetId) : "";
  console.log("assetListassetList", assetTemp);

  return assetTemp?.name;
};

const generateContactBodyText = (
  text: string | number,
  style: any,
  icon: any,
  type?: string
) => {
  const generatePostfixText = () => {
    if (type) {
      return (
        <>
          <span>
            {"("}
            {type}
            {")"}
          </span>
        </>
      );
    }
  };
  if (text) {
    return (
      <div className={style.contactChipBodyTextContainer}>
        {icon}
        <div style={{ fontSize: "12px" }}>
          {text}
          {generatePostfixText()}
        </div>
      </div>
    );
  }
};

const generateAttachmentAccordion = (
  attachment: any,
  onRemoveAttchment: any
) => {
  return (
    <SimpleAccordion
      isExpand={attachment?.length > 0 ? true : false}
      name="panel1"
      header={
        <>
          <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            {attachment?.length > 0 && (
              <Avatar
                sx={{
                  width: 24,
                  height: 24,
                  fontSize: "12px",
                  backgroundColor: "#2F80ED",
                }}
              >
                {attachment.length}
              </Avatar>
            )}
            <div>Attachments</div>
          </div>
        </>
      }
      body={
        <>
          {attachment?.length > 0 ? (
            <div
              style={{
                width: "100%",
                height: "auto",
                padding: "25px",
              }}
            >
              <Slider {...settings}>
                {attachment?.map((item: any, key: number) => {
                  const fileType = item?.Type
                    ? item.Type
                    : getMimeTypeFromUrl(item?.attachmentPath).type;
                  const fileAttachment = item?.file
                    ? item.file
                    : item.attachmentPath;

                  console.log("AssetImagesAssetImages", fileType);

                  return (
                    <Box sx={{ position: "relative" }}>
                      <Box
                        sx={{
                          position: "relative",
                          width: "100px",
                          margin: "auto",
                        }}
                        onClick={() => {
                          // deleteAttachment(key);
                        }}
                      >
                        <IconButton
                          aria-label="delete"
                          style={{
                            position: "absolute",
                            top: -10,
                            right: -20,
                            backgroundColor: "white",
                          }}
                          onClick={() => {
                            onRemoveAttchment(key);
                          }}
                        >
                          {" "}
                          <RemoveCircleIcon sx={{ color: "red" }} />
                        </IconButton>
                        {fileType == "unknown" && (
                          <SmartDisplayIcon
                            sx={{
                              width: "100px",
                              height: "100px",
                              color: "gray",
                              margin: "auto",
                              borderRadius: "10px",
                              border: "1px solid gray",
                            }}
                            onClick={() => {
                              window.open(fileAttachment, "_blank")?.focus();
                            }}
                          />
                        )}

                        {fileType == "application/pdf" && (
                          <PictureAsPdfIcon
                            sx={{
                              width: "100px",
                              height: "100px",
                              color: "gray",
                              margin: "auto",
                              borderRadius: "10px",
                              border: "1px solid gray",
                            }}
                            onClick={() => {
                              var pdfResult = fileAttachment;
                              let pdfWindow: any = window.open("");
                              pdfWindow.document.write(
                                "<iframe width='100%' height='100%' src='" +
                                  encodeURI(pdfResult) +
                                  "'></iframe>"
                              );
                            }}
                          />
                        )}
                        {fileType ==
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                          <TextSnippetIcon
                            sx={{
                              width: "100px",
                              height: "100px",
                              color: "gray",
                              margin: "auto",
                              borderRadius: "10px",
                              border: "1px solid gray",
                            }}
                            onClick={() => {
                              const docxUrl = fileAttachment;

                              const link = document.createElement("a");
                              link.href = docxUrl;
                              link.download = docxUrl.substring(
                                docxUrl.lastIndexOf("/") + 1
                              );
                              link.target = "_blank";

                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                            }}
                          />
                        )}
                        {fileType == "image/png" && (
                          <img
                            src={`${fileAttachment}`}
                            style={{
                              width: "100px",
                              height: "100px",
                              margin: "auto",
                              borderRadius: "10px",
                              border: "1px solid gray",
                            }}
                            alt="image"
                            onClick={() => {
                              let image = new Image();
                              image.src = fileAttachment;
                              var newTab: any = window.open();
                              newTab.document.body.innerHTML = image.outerHTML;
                            }}
                          />
                        )}
                        {fileType == "image/jpeg" && (
                          <img
                            src={`${fileAttachment}`}
                            style={{
                              width: "100px",
                              height: "100px",
                              margin: "auto",
                              borderRadius: "10px",
                              border: "1px solid gray",
                            }}
                            alt="image"
                            onClick={() => {
                              let image = new Image();
                              image.src = fileAttachment;
                              var newTab: any = window.open();
                              newTab.document.body.innerHTML = image.outerHTML;
                            }}
                          />
                        )}
                        {fileType == "image/bmp" && (
                          <img
                            src={`${fileAttachment}`}
                            style={{
                              width: "100px",
                              height: "100px",
                              margin: "auto",
                              borderRadius: "10px",
                              border: "1px solid gray",
                            }}
                            alt="image"
                            onClick={() => {
                              let image = new Image();
                              image.src = fileAttachment;
                              var newTab: any = window.open();
                              newTab.document.body.innerHTML = image.outerHTML;
                            }}
                          />
                        )}

                        {fileType == "image/jpg" && (
                          <img
                            src={`${fileAttachment}`}
                            style={{
                              width: "100px",
                              height: "100px",
                              margin: "auto",
                              borderRadius: "10px",
                              border: "1px solid gray",
                            }}
                            alt="image"
                            onClick={() => {
                              let image = new Image();
                              image.src = fileAttachment;
                              var newTab: any = window.open();
                              newTab.document.body.innerHTML = image.outerHTML;
                            }}
                          />
                        )}
                        <p>{item?.fileName}</p>
                        {/* <HighlightOffOutlinedIcon /> */}
                      </Box>
                    </Box>
                  );
                })}
              </Slider>
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
              >
                <AttachmentIcon
                  className="newticket-Icon"
                  sx={{ width: "50px", height: "50px", color: "#2F80ED" }}
                />
                <div>Selected Attachments will appear here</div>
              </div>
            </>
          )}
        </>
      }
    />
  );
};

const generateAssetAccordion = (
  permissions: any,
  assetSelected: any,
  onRemoveAsset: any,
  assetsList: any
) => {
  if (permissions.isSuperAdmin || permissions.assetAdminAccess) {
    return (
      <SimpleAccordion
        isExpand={assetSelected?.length > 0 ? true : false}
        name="panel1"
        header={
          <>
            <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
              {assetSelected?.length > 0 && (
                <Avatar
                  className="Asset-count"
                  sx={{
                    width: 24,
                    height: 24,
                    fontSize: "12px",
                    backgroundColor: "#2F80ED",
                  }}
                >
                  {assetSelected?.length}
                </Avatar>
              )}
              <div>Assets</div>
            </div>
          </>
        }
        body={
          <div>
            {assetSelected?.length == 0 && (
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "20px",
                  marginBottom: "25px",
                }}
              >
                <HomeRepairServiceIcon
                  className="newticket-Icon"
                  sx={{ width: "50px", height: "50px", color: "#2F80ED" }}
                />
                Selected Assets will appear here
              </div>
            )}

            {assetSelected?.map((item: any, index: number) => {
              console.log("assetSelected", item);
              return (
                <>
                  <div
                    className="Assets-display"
                    style={{
                      height: "40px",
                      position: "relative",
                      width: "100%",
                      border: "0.5px solid #535252",
                      borderRadius: "5px",
                      marginBottom: "10px",
                      marginTop: "10px",
                      backgroundColor: "white",
                      padding: 5,
                      wordBreak: "break-word",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", gap: 10 }}>
                      <div
                        style={{
                          borderRadius: "5px",
                          height: "10px",
                          boxShadow:
                            "7px 10px 17px -4px rgba(115,109,109,0.48)",
                        }}
                      >
                        {item?.src}
                      </div>

                      <span style={{ display: "flex", alignSelf: "center" }}>
                        {" "}
                        {/* {generateAssetName(assetsList, item.id)} */}
                        {item.name}
                      </span>
                    </div>
                    <IconButton
                      aria-label="delete"
                      style={{ display: "flex", alignSelf: "center" }}
                      onClick={() => {
                        let tempSelected = [...assetSelected];
                        let temp = tempSelected?.filter((ele: any) => {
                          if (item.id == ele.id) {
                            tempSelected.splice(index, 1);
                          }
                          // setFormData({
                          //   ...formData,
                          //   assetSelected: tempSelected,
                          // });
                          onRemoveAsset(tempSelected);
                        });
                      }}
                    >
                      {" "}
                      <RemoveCircleIcon sx={{ color: "red" }} />
                    </IconButton>
                  </div>
                </>
              );
            })}
          </div>
        }
      />
    );
  }
};

const generateCirculationListAccordion = (
  userFormData: any,
  filterEmailData: any,
  setFilterEmailData: any,
  contacts: any
) => {
  return (
    <SimpleAccordion
      isExpand={false}
      name="panel1"
      header="Circulated Email list"
      body={
        <div
          style={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
            // marginTop: "20px",
            marginBottom: "25px",
            width: "100%",
          }}
        >
          <FilterEmail
            editData={userFormData}
            formData={{
              contactList: contacts,
            }}
            filterEmailData={filterEmailData}
            setFilterEmailData={setFilterEmailData}
          />
        </div>
      }
    />
  );
};

const GenerateContactChip = ({
  contact,
  allContactImgUrls,
  style,
  type,
}: {
  contact: any;
  allContactImgUrls: any;
  style: any;
  type?: string;
}) => {
  if (contact) {
    return (
      <>
        <div
          className={`${style.contactChipContainer} editticket-quickcontact`}
        >
          <div className={style.contactChipContainerLabelOnRightContainer}>
            {type == "primary" ? "Primary" : "Secondary"}
          </div>

          {generateContactAlertIcon(contact?.alert, style)}

          <div className={style.contactChipAvatarContainer}>
            {generateContacImg(allContactImgUrls, contact, 40)}
          </div>

          <div className={style.contactChipBodyContainer}>
            {generateContactName(contact?.name, contact?.isVIP, style)}

            <div className={style.contactChipBody}>
              {generateContactBodyText(
                contact?.mobilePhone,
                style,
                <LocalPhoneIcon className={style.contactChipBodyIcon} />
              )}
              {generateContactBodyText(
                contact?.officeLocation,
                style,
                <LocationOnIcon className={style.contactChipBodyIcon} />
              )}
              {generateContactBodyText(
                contact?.jobTitle,
                style,
                <WorkIcon className={style.contactChipBodyIcon} />
              )}
              {generateContactBodyText(
                contact?.whatsAppNumber,
                style,
                <WhatsAppIcon
                  className={style.contactChipBodyIcon}
                  sx={{ color: "green" }}
                />
              )}
              {generateContactBodyText(
                contact?.knownAs,
                style,
                <RecordVoiceOverIcon className={style.contactChipBodyIcon} />,
                "Known as"
              )}
              {generateContactBodyText(
                contact?.userName,
                style,
                <BadgeIcon className={style.contactChipBodyIcon} />,
                "Username"
              )}
              {generateContactBodyText(
                contact?.email,
                style,
                <AlternateEmailIcon
                  className={style.contactChipBodyIcon}
                  sx={{ color: "blue" }}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

const MemoizedComponent: React.FC<iMemoizedComponentForGenerateContactChip> = ({
  children,
  contact = null,
}) => {
  return useMemo(() => {
    return children;
  }, [contact]);
};

const generateBodyForContact = (
  primaryContact: any,
  secondaryContact: any,
  allContactImgUrls: any,
  style: any
) => {
  if (primaryContact || secondaryContact) {
    return (
      <>
        <MemoizedComponent contact={primaryContact}>
          <GenerateContactChip
            contact={primaryContact}
            allContactImgUrls={allContactImgUrls}
            style={style}
            type="primary"
          />
        </MemoizedComponent>

        <MemoizedComponent contact={secondaryContact}>
          <GenerateContactChip
            type="second"
            contact={secondaryContact}
            allContactImgUrls={allContactImgUrls}
            style={style}
          />
        </MemoizedComponent>
      </>
    );
  } else {
    return (
      <div className={style.contactCardFallBackContainer}>
        <ContactsIcon
          className={`${style.contactFallBackImg} newticket-Icon`}
        />
        Selected Contacts will appear here
      </div>
    );
  }
};

const RightPanel = ({
  primaryContact,
  secondaryContact,
  style,
  contacts,
  permissions,
  assetSelected,
  onRemoveAsset,
  attachment,
  onRemoveAttchment,
  userFormData,
  filterEmailData,
  setFilterEmailData,
}: {
  primaryContact: NullableArgument<CommonDataForFormDataProperties>;
  secondaryContact: NullableArgument<CommonDataForFormDataProperties>;
  style: any;
  contacts: any;
  permissions: any;
  assetSelected: any;
  onRemoveAsset: any;
  attachment: any;
  onRemoveAttchment: any;
  userFormData: any;
  setFilterEmailData: any;
  filterEmailData: any;
}) => {
  const dispatch = useDispatch();

  const allContactImgUrls = useSelector(
    (state: IState) => state?.ContactsReducer?.contactImgDetails
  );

  const AssetsList = useSelector(
    (state: IState) => state?.AssetsReducer?.AllAssets
  );

  useEffect(() => {
    const allContactImgUrlIds: any[] = [];

    console.log("ContactListImgs", allContactImgUrls);

    contacts?.forEach((i: any) => {
      if (i?.profilePictureId && i?.profilePictureId !== 0) {
        allContactImgUrlIds.push(i?.profilePictureId);
      }
    });
    dispatch(downloadMultiFilesById(allContactImgUrlIds.join()));
  }, []);

  useEffect(() => {
    console.log("ContactListImgs----allContactImgUrls", allContactImgUrls);
  }, [allContactImgUrls]);

  return (
    <>
      <div className={style.rightSection} id="new-tickets">
        <div className={style.accordionContainer}>
          <SimpleAccordion
            isExpand={primaryContact || secondaryContact ? true : false}
            name="panel1"
            header={
              <>
                <div className={style.accordionHeaderContainer}>
                  {generateContactImgGroup(
                    primaryContact,
                    secondaryContact,
                    allContactImgUrls
                  )}
                  <span>Contact Details</span>
                </div>
              </>
            }
            body={
              <>
                <div className={style.contactCardContainer}>
                  {generateBodyForContact(
                    primaryContact,
                    secondaryContact,
                    allContactImgUrls,
                    style
                  )}
                </div>
              </>
            }
          />

          {generateAssetAccordion(
            permissions,
            assetSelected,
            onRemoveAsset,
            AssetsList.assets
          )}

          {generateAttachmentAccordion(attachment, onRemoveAttchment)}

          {generateCirculationListAccordion(
            userFormData,
            filterEmailData,
            setFilterEmailData,
            contacts
          )}
        </div>
      </div>
    </>
  );
};

export default RightPanel;
