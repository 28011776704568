import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getWallboardByCode, getWallboardById } from "../../../redux/actions/Wallboard/Wallboard";
import { Box, Grid, Typography } from "@mui/material";
import Breadcrumbs from "../../common2/Breadcrumbs";
import VerticalChart from "../../charts/horizontal-chart/VerticalChart";
import HorizontalChart from "../../charts/horizontal-chart/HorizontalChart";
import LineChart from "../../charts/line/LineChart";
import PieChart from "../../charts/pie/PieChart";
import DoughnutChart from "../../charts/dougnut/DoughnutChart";
import { IPieData } from "./InterfaceWallBoard";
import { alertMessage } from "../../../redux/actions/SystemIcons/systemIcons";
import { getTenantCodeBySubDomain } from "../../../redux/actions/Login/Login";
import HorizontalChartActual from "../../charts/horizontal-chart/HorizontalChartActual";

const ViewChart = () => {
    const param = useParams();
    const dispatch = useDispatch();
    const [viewVal, setViewVal] = useState<any>(null);
    const [viewValCode, setViewValCode] = useState<any>(null);
    const [wallBoardTypeList, setWallBoardTypeList] = useState<any[]>([]);
    const [chartsInitialized, setChartsInitialized] = useState<boolean>(false);
    const [externalWebLink, setExternalWebLink] = useState('');
    const [tenantCode, setTenantCode] = useState("")
    const CurrLocation = window.location.origin
    const subDomain = window.location.host.split('.')?.[0];

    const priorityName: any = {
        "P1": 409,
        "P2": 88,
        "P3": 60,
        "P4": 9,
    };

    const AgentData: any = {
        "Agent1": 0,
        "Agent2": 3,
        "Agent3": 0,
        "Agent4": 1,
    }

    const ticketStatus: any = {
        "Status1": 36,
        "Status2": 159,
        "Status3": 312,
        "Status4": 12,
    };

    const groupData: any = {
        "1st Line": 1,
        "2nd Line": 2,
        "3rd Line": 3,
        "4th Line": 4,
    }

    const ticketTypes: any = {
        "Type 1": 36,
        "Type 2": 81,
        "Type 3": 65,
        "Type 4": 80,
    }

    useEffect(() => {
        if (param?.code && tenantCode) {

            dispatch(getWallboardByCode(param.code, getWallboardByCodeCB, tenantCode))
        }
        if (param?.id) {
            dispatch(getWallboardById(param.id, getWallboardByIdCB))
        }
    }, [param?.id, tenantCode])
    useEffect(() => {
        if (param?.code) {
            console.log("subDomain",subDomain);
            
            if (subDomain && subDomain !== 'portal' && !subDomain.includes('localhost')) {
                dispatch(getTenantCodeBySubDomain(`${subDomain}`, TenantCodeCB));
            }
        }
    }, [param?.code])

    const TenantCodeCB =(resStatus: string, res: any)=>{
        if (resStatus === "0") {
            setTenantCode(res.tenantcode);
        }
    }
    const getWallboardByIdCB = (resStatus: string, res: any) => {
        console.log("getWallboardByIdCB", res, resStatus);
        if (resStatus === "0") {
            setViewVal(res);
        }
    };
    const getWallboardByCodeCB = (resStatus: string, res: any) => {
        console.log("getWallboardByIdCB", res, resStatus);
        if (resStatus === "0") {
            setViewValCode(res);
        }
    };

    useEffect(() => {

        if (viewVal?.wallBoardTypeList && !chartsInitialized) {
            const newWallBoardTypeList = viewVal.wallBoardTypeList.map((i: any) => {
                const { chartType, data,isActive } = i;
                if (!isActive) {
                    return null
                }
                if (chartType && data.datasets[0].label) {
                    let newData;
                    switch (data.datasets[0].label) {
                        case "Group":
                            newData = groupData;
                            break;
                        case "Priority":
                            newData = priorityName;
                            break;
                        case "TicketStatus":
                            newData = ticketStatus;
                            break;
                        case "Agent":
                            newData = AgentData;
                            break;
                        case "TicketType":
                            newData = ticketTypes;
                            break;
                        default:
                            newData = {};
                    }

                    const chart: any = renderChart(chartType, newData, data.datasets[0].label);
                    return { chartType: chartType, data: chart, isActive: true };
                }
                return null;
            })
            setWallBoardTypeList(newWallBoardTypeList);
            setChartsInitialized(true);
            setExternalWebLink(`${CurrLocation}/#/ViewChart/${viewVal.code}`)
        }
    }, [viewVal, chartsInitialized]);

    const extractData = (dataObject: any, value: any) => {
        if (dataObject && dataObject[value]) {
            return [dataObject[value].dataList, dataObject[value].count] || [];
        }
        return [];
    };
    useEffect(() => {
        if (viewValCode?.wallBoardTypeList && !chartsInitialized) {
            const newWallBoardTypeList = viewValCode.wallBoardTypeList.map((i: any) => {
                const { chartType, value ,isActive} = i;
                if (!isActive) {
                    return null
                }
                if (chartType && value) {
                    const newData = extractData(i.wallboardData, value)

                    const chart = renderChart(chartType, newData[0], value);
                    return { chartType: chartType, data: chart, count: newData[1], isActive: true };
                }
                return null;
            })

            setWallBoardTypeList(newWallBoardTypeList);
            setChartsInitialized(true);
        }
    }, [viewValCode, chartsInitialized]);

    const renderChart = (type: any, data: any, property: string = "") => {
        const labels = Object.keys(data);
        const values = Object.values(data);
        const chartData: IPieData = {
            labels: labels,
            chartType: type,
            datasets: [
                {
                    label: property,
                    data: values,
                    backgroundColor: [
                        "rgba(75,192,192,0.2)",
                        "rgba(153,102,255,0.2)",
                        "rgba(255,159,64,0.2)",
                        "rgba(255,99,132,0.2)",
                        "rgba(54,162,235,0.2)",
                        "rgba(255,206,86,0.2)",
                    ],
                    borderColor: [
                        "rgba(75,192,192,1)",
                        "rgba(153,102,255,1)",
                        "rgba(255,159,64,1)",
                        "rgba(255,99,132,1)",
                        "rgba(54,162,235,1)",
                        "rgba(255,206,86,1)",
                    ],
                    borderWidth: 1,
                },
            ],
        };
        return chartData;
    };

    const ChartItem = useMemo(() => {
        // Filter the list to include only active items
        const activeItems = wallBoardTypeList.filter(chartComponent => chartComponent?.isActive);
    
        // Slice the first 9 active items
        const itemsToShow = activeItems.slice(0, 9);
    
        return itemsToShow.map((chartComponent, index) => {
            switch (chartComponent?.chartType) {
                case "VerticalBarGraph":
                    return (
                        <Grid item md={4} xs={12} key={index} height={400} style={{ marginBottom: '25px' }}>
                            <VerticalChart label={chartComponent?.data?.labels} dataSets={chartComponent.data.datasets} />
                            {viewValCode &&
                                <span style={{ fontSize: "12px" }}>Count : {chartComponent.count}</span>
                            }
                        </Grid>
                    );
                case "HorizontalBarGraph":
                    return (
                        <Grid item md={4} xs={12} key={index} height={400} style={{ position: "relative", marginBottom: '25px' }}>
                            <HorizontalChartActual label={chartComponent?.data?.labels} dataSets={chartComponent.data.datasets} />
                            {viewValCode &&
                                <span style={{ fontSize: "12px" }}>Count : {chartComponent.count}</span>
                            }
                        </Grid>
                    );
                case "LineGraph":
                    return (
                        <Grid item md={4} xs={12} key={index} height={400} style={{ marginBottom: '25px' }}>
                            <LineChart datasets={chartComponent.data.datasets} labels={chartComponent?.data?.labels} />
                            {viewValCode &&
                                <span style={{ fontSize: "12px" }}>Count : {chartComponent.count}</span>
                            }
                        </Grid>
                    );
                case "PieGraph":
                    return (
                        <Grid item md={4} xs={12} key={index} height={400} style={{ marginBottom: '25px' }}>
                            <PieChart labels={chartComponent?.data?.labels} datasets={chartComponent.data.datasets} />
                            {viewValCode &&
                                <span style={{ fontSize: "12px" }}>Count : {chartComponent.count}</span>
                            }
                        </Grid>
                    );
                case "DoughnutGraph":
                    return (
                        <Grid item md={4} xs={12} key={index} height={400} style={{ marginBottom: '25px' }}>
                            <DoughnutChart
                                labels={chartComponent?.data?.labels}
                                datasets={chartComponent.data.datasets}
                            />
                            {viewValCode &&
                                <span style={{ fontSize: "12px" }}>Count : {chartComponent.count}</span>
                            }
                        </Grid>
                    );
                default:
                    return (
                        <Grid item md={4} xs={12} key={index}>
                            <>Choose dropdowns to render chart!</>
                        </Grid>
                    );
            }
        });
    }, [wallBoardTypeList, viewValCode]);
    
    const handleCopyLink = (e: any) => {
        e.preventDefault();
        if (externalWebLink) {
            navigator.clipboard.writeText(externalWebLink)
                .then(() => {
                    dispatch(alertMessage(`Success! External WallBoard Link Copied`, "success"))
                    console.log('Link copied to clipboard');
                })
                .catch((err) => {
                    dispatch(alertMessage(`Failed to Copy External WallBoard Link `, "error"))
                    console.error('Failed to copy link: ', err);
                });
        }
    };
    return (
        <>
            <Box className="p-1">
                {param.id &&
                    <Breadcrumbs
                        data={[
                            {
                                title: 'Reports Management ',
                                path: '/ReportsManagement'
                            },
                            {
                                title: 'WallBoard',
                                path: '/WallBoard'
                            },
                            {
                                title: `View Wallboard`,
                                path: `${"/ViewChart/" + param.id}`
                            }
                        ]}
                    />

                }
                <Box className="white-bg p-2 mt-1 add-article">
                    {param.id &&
                        <>
                            <Typography>
                                {"View Wallboard"}
                            </Typography>
                            <hr />
                        </>
                    }
                        <Typography>
                            {viewVal ? `WallBoard - ${viewVal?.name}` : `WallBoard - ${viewValCode?.name}`}
                        </Typography>

                    <div style={{marginTop:'10px'}}>
                        <Typography>
                            {viewVal && (
                                <a href="#" onClick={handleCopyLink}>
                                    Copy External WallBoard Link here
                                </a>
                            )}
                        </Typography>
                    </div>
                    <Grid container spacing={1} alignItems={"end"} style={{ marginTop: "25px" }}>
                        {ChartItem}
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default ViewChart;
