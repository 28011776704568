import { addDays, subWeeks, subYears } from "date-fns"

export const filterInitialState = {
    searchVal: "",
    contacts: [],
    agents: [],
    agentsWithGroup: [],
    statuses: [],
    priorities: [],
    ticketTypes: [],
    OrgList: [],
    sortType: "unset",
    rangeVal:null,
    categoryObj:{}
}
const initialState1 = {
    layout:"Status",
    groupShrinked:false,
    columnShrinked:true,
    showLoader:false,
    showLinkLoader:false,
}
const ticketViewInfo = {
    info: null,
    contactPopover:null,
    contactPopupInfo:null,
    resolvePopupId:{},
    articlePopupInfo:{},
    allTicketsById:{},
    holdTicketPopupInfo:null,
    addUpdate:null,
    whatsApp:null,
    sendEmailView: null,
    linkMerge: null
}

const mobileFilterInit = {
    search:"",
    dateRange:{
        startDate: subWeeks(new Date(), 4),
        endDate: new Date(),
        key: "openDate",
    },
    otherValues:{},
    loggedInAgentId : 0,
    sortProp : "OpenDateTime",
    sortType : "Asc"
}
export const mobileFiltersReducer: any = (state = mobileFilterInit, action: { type: any; payload: any; }) => {
    const {type,payload} = action;
    switch (action.type) {
        case 'MOBILE_TCKT_AGENTS_VALUE':
            return {
                ...state,
                loggedInAgentId : action.payload
            };
        case 'TCKT_MOBILE_SEARCH_VALUE':
            return {
                ...mobileFilterInit,
                search : action.payload
            };
        case 'TCKT_MOBILE_SORT_VALUE':
            return {
                ...state,
                ...action.payload
            };
        case 'UNSET_MOBILE_FILTERS':
            return mobileFilterInit;
        case 'STORE_MOBILE_FILTERS_VALUE':
            return {
                ...state,
                ...action.payload
            }
        case 'TOGGLE_MOBILE_FILTER_VALUE':
                return {
                    ...state,
                    otherValues:{
                        ...state.otherValues,
                        ...payload
                    },
                }
        default:
            return state;
    }
}
const filtersTicketManagementReducer: any = (state = filterInitialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case 'UNSET_FILTERS':
            return  {
                ...filterInitialState
            };
        case 'RESET_FILTER':
            return {
                ...state,
                ...action.payload,
            };
        case 'TCKT_SEARCH_VALUE':
            return {
                ...state,
                searchVal: action.payload,
        };
        case 'TCKT_CONTACT_VALUE':
            return {
                ...state,
                contacts: action.payload,
            };
        case 'TCKT_STATUS_VALUE':
            return {
                ...state,
                statuses: action.payload,
            };
        case 'TCKT_TICKET_TYPE_VALUE':
            return {
                ...state,
                ticketTypes: action.payload,
            };
        case 'TCKT_PRIORITIES_VALUE':
            return {
                ...state,
                priorities: action.payload,
            };
        case 'TCKT_ORG_VALUE':
            return {
                ...state,
                OrgList: action.payload,
            };
        case 'TCKT_SORT_VALUE':
            return {
                ...state,
                sortType: action.payload,
            };
        case 'TCKT_GROUP_AGENTS_VALUE':
            return {
                ...state,
                agents: [],
                agentsWithGroup : action.payload,
            };
        case 'TCKT_AGENTS_VALUE':
            return {
                ...state,
                agentsWithGroup: [],
                agents: action.payload,
            };
        case 'TCKT_RANGE_VALUE':
            return {
                ...state,
                rangeVal: action.payload,
            };
        default:
            return state;
    }
}
export const filtersTicketManagementReducerForReport: any = (state = filterInitialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case 'RESET_FILTER_REPORT':
            return {
                ...state,
                ...action.payload,
            };
        case 'TCKT_SEARCH_VALUE_REPORT':
            return {
                ...state,
                searchVal: action.payload,
        };
        case 'TCKT_CONTACT_VALUE_REPORT':
            return {
                ...state,
                contacts: action.payload,
            };
        case 'TCKT_STATUS_VALUE_REPORT':
            return {
                ...state,
                statuses: action.payload,
            };
        case 'TCKT_TICKET_TYPE_VALUE_REPORT':
            return {
                ...state,
                ticketTypes: action.payload,
            };
        case 'TCKT_PRIORITIES_VALUE_REPORT':
            return {
                ...state,
                priorities: action.payload,
            };
        case 'TCKT_ORG_VALUE_REPORT':
            return {
                ...state,
                OrgList: action.payload,
            };
        case 'TCKT_SORT_VALUE_REPORT':
            return {
                ...state,
                sortType: action.payload,
            };
        case 'TCKT_GROUP_AGENTS_VALUE_REPORT':
            return {
                ...state,
                agents: [],
                agentsWithGroup : action.payload,
            };
        case 'TCKT_AGENTS_VALUE_REPORT':
            return {
                ...state,
                agentsWithGroup: [],
                agents: action.payload,
            };
        case 'TCKT_RANGE_VALUE_REPORT':
            return {
                ...state,
                rangeVal: action.payload,
            };
        default:
            return state;
    }
}

export const filtersTicketManagementReducerForCSV: any = (state = filterInitialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case 'RESET_FILTER_CSV':
            return {
                ...state,
                ...action.payload,
            };
        case 'TCKT_SEARCH_VALUE_CSV':
            return {
                ...state,
                searchVal: action.payload,
        };
        case 'TCKT_CONTACT_VALUE_CSV':
            return {
                ...state,
                contacts: action.payload,
            };
        case 'TCKT_STATUS_VALUE_CSV':
            return {
                ...state,
                statuses: action.payload,
            };
        case 'TCKT_TICKET_TYPE_VALUE_CSV':
            return {
                ...state,
                ticketTypes: action.payload,
            };
        case 'TCKT_PRIORITIES_VALUE_CSV':
            return {
                ...state,
                priorities: action.payload,
            };
        case 'TCKT_ORG_VALUE_CSV':
            return {
                ...state,
                OrgList: action.payload,
            };
        case 'TCKT_SORT_VALUE_CSV':
            return {
                ...state,
                sortType: action.payload,
            };
        case 'TCKT_GROUP_AGENTS_VALUE_CSV':
            return {
                ...state,
                agents: [],
                agentsWithGroup : action.payload,
            };
        case 'TCKT_AGENTS_VALUE_CSV':
            return {
                ...state,
                agentsWithGroup: [],
                agents: action.payload,
            };
        case 'TCKT_RANGE_VALUE_CSV':
            return {
                ...state,
                rangeVal: action.payload,
            };
        default:
            return state;
    }
}
export const LayoutTicketManagementReducer: any = (state = initialState1, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case 'GROUP_SHRINK_VAL':
            return {
                ...state,
                groupShrinked: action.payload,
            };
        case 'COLUMN_SHRINK_VALUE':
            return {
                ...state,
                columnShrinked: action.payload,
            };
        case 'TCKT_LAYOUT_VALUE':
            return {
                ...state,
                layout: action.payload,
            };
        case "SHOW_LOADER":
            return {
                ...state,
                showLoader: action.payload,
            };
        case "SHOW_LINK_LOADER":
            return {
                ...state,
                showLinkLoader: action.payload,
            };
        default:
            return state;
    }
}


const groupInitialVals = {
    groupVals:{}
}
export const GroupInfoViewReducer: any = (state = groupInitialVals, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case 'STORE_GROUP_INFO':
            return {
                ...state,
                groupVals: action.payload,
            };  
        case 'UPDATE_GROUP_INFO_VAL':
            const {groupName,agentId} = action.payload;
            // debugger;
            const tempObj:any = JSON.parse(JSON.stringify(state));
            if(!tempObj?.groupVals?.group){
                tempObj.groupVals["group"] = {};
            }
            if(!tempObj?.groupVals?.group?.[groupName]){
                tempObj.groupVals["group"][groupName] = {};
            }
            tempObj.groupVals.group[groupName].count += 1;
            if(!tempObj?.groupVals?.group?.[groupName]?.agentList){
                tempObj.groupVals.group[groupName].agentList = {}
            }
            if(!tempObj?.groupVals?.group?.[groupName]?.agentList?.[agentId]){
                tempObj.groupVals.group[groupName].agentList[agentId] = 0;
            }
            tempObj.groupVals.group[groupName].agentList[agentId] += 1;

            return tempObj;
        default:
            return state;
    }
}

export const TicketViewReducer: any = (state = ticketViewInfo, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case 'TICKET_VIEW_INFO':
            return {
                ...state,
                info: action.payload,
            };
        case 'ALL_TICKETS_BY_ID':
            return {
                ...state,
                allTicketsById: {...state.allTicketsById,[`${action?.payload?.id}`] : action.payload},
            };
        case 'TICKET_POPOVER_INFO':
            return {
                ...state,
                contactPopover: action.payload,
            };
        case 'RESOLVE_POPUP_INFO':
            return {
                ...state,
                resolvePopupId: action.payload,
            };
        case 'ARTICLE_POPUP_INFO':
            return {
                ...state,
                articlePopupInfo: action.payload,
            };
        case 'HOLD_TICKET_POPUP_INFO':
                return {
                    ...state,
                    holdTicketPopupInfo: action.payload,
                };
        case 'CONTACT_POPUP_INFO':
            return {
                ...state,
                contactPopupInfo: action.payload,
            };
        case 'ADD_UPDATE_POPUP_INFO':
            return {
                ...state,
                addUpdate: action.payload,
            };
        case 'LINK_MERGE_POPUP_INFO':
            return {
                ...state,
                linkMerge: action.payload,
            };
        case 'WHATSAPP_POPUP_INFO':
            return {
                ...state,
                whatsApp: action.payload,
            };   
            case 'SEND_EMAIL_VIEW':
                return {
                    ...state,
                    sendEmailView: action.payload,
                };  
            case 'GET_CATEGORY_BY_ID':
                return {
                    ...state,
                    categoryObj: action.payload,
                };  
        default:
            return state;
    }
}

export default filtersTicketManagementReducer;
