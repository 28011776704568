import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTicketByID } from "../../../redux/actions/TicketsManagement/TicketsManagement";
import { IState } from "../../../redux/reducers/rootReducers";
import { getTicketsById } from "../../../services/dashboard/dashboard.service";
import Form, { generateTagFromEdit } from "./Form";
import {
  iPermissions,
  iTicketManagementProperties
} from "./Type";

export const FindItem = (list: any, searchItem: any) => {
  let temp = list?.find((x: any) => x?.id == searchItem);
  return temp ? temp : 0;
};
export const FindItemByName = (list: any, searchItem: any) => {
  let temp = list?.find((x: any) => x?.code === searchItem);
  return temp ? temp : 0;
};
const getTicketsByIdFunc = async (id: any) => {
  let res = await getTicketsById(id);
  return res;
};

const modifyLabel = (item: any) => {
  if (item) {
    return {
      label: `${item.name},(${item.email})`,
      name: item.name,
      value: item.name,
      id: item.id,
      knownAs: item.knownAs,
      userName: item.userName,
      jobTitle: item.jobTitle,
      email: item.email,
      whatsAppNumber: item.whatsAppNumber,
      company: item.companyName,
      place: item.officeLocation,
      no: item.mobilePhone,
      isVIP: item.isVIP,
      alert: item.alert,
      profilePictureId: item.profilePictureId,
      icon: <AccountCircleOutlinedIcon sx={{ color: "#a6a6a6" }} />,
    };
  } else {
    return null;
  }
};

const generateCategoryLabel = (catId: any, catList: any) => {
  const categoryObj: any = {};
  catList?.map((i: any) => {
    categoryObj[i.id] = i.name;
    i.subCategoryList.map((j: any) => {
      categoryObj[j.id] = j.name;
      j.itemList.map((k: any) => {
        categoryObj[k.id] = k.name;
        k.childrenItemList.map((l: any) => {
          categoryObj[l.id] = l.name;
        });
      });
    });
  });
  console.log("categoryObj", categoryObj);
  if (categoryObj?.[catId]) {
    return {
      label: categoryObj?.[catId],
      id: catId,
    };
  }
};

const Edit = ({
  ticketManagementProperties,
  permissions,
  style,
  id,
}: {
  ticketManagementProperties: iTicketManagementProperties;
  permissions: iPermissions;
  style: any;
  id: any;
}) => {
  const dispatch = useDispatch();

  const Category = useSelector(
    (state: IState) => state?.categoryReducer?.Data?.data
  );

  
  useEffect(() => {
    if (!id) return;
    getTicketInfo();
  }, [id]);
  const getTicketInfo = () => {
    dispatch(getTicketByID(id, updatelogs))
  }
  const [editData, setEditData] = useState<any>(false);

  const updatelogs = (resStatus: string, res: any) => {
      if (resStatus === '1') return;
      const defaultValue: any = {
        id: id,
        primaryContact: modifyLabel(res?.ticketLogs?.contacts),
        ticketType: FindItem(
          ticketManagementProperties.TicketTypes,
          res?.ticketLogs?.ticketTypeId
        ),
        secondaryContact: modifyLabel(res?.ticketLogs?.secondaryContacts),
        priority: FindItem(
          ticketManagementProperties.priorities,
          res?.ticketLogs?.priorityId
        ),
        source: FindItemByName(
          ticketManagementProperties.ticketSources,
          res?.ticketLogs?.sourceCode
        ),
        subject: res?.ticketLogs?.title,
        openDate: res?.ticketLogs?.openDateTime,
        desc: res?.ticketLogs?.description,
        nextActionDesc: res?.ticketLogs?.nextActionDescription,
        nextActionDate: res?.ticketLogs?.firstResponseDueDateTime,
        dueDate: res?.ticketLogs?.dueDateTime,
        tag: generateTagFromEdit(res?.ticketTagList),
        asset: res?.assets,

        category: generateCategoryLabel(res?.ticketLogs?.categoryId, Category),
        groupId: res?.ticketLogs?.groupId,
        agentId: res?.ticketLogs?.agentId,
        statusId: res?.ticketLogs?.statusId,
        note: res?.ticketLogs?.note ? res?.ticketLogs?.note : "",
        effort: res?.ticketLogs?.effort,
        totalEffort: res.totalEffort,
        attachment: res?.attachmentDetails,
        circulationListTO: res.ticketLogs?.circulationListTO,
        circulationListBCC: res.ticketLogs?.circulationListBCC,
        circulationListCC: res.ticketLogs?.circulationListCC,
      };
      console.log("category defaultValue show", defaultValue);

      setEditData(defaultValue);
  }


  return (
    <>
        {editData && (
          <Form
            ticketManagementProperties={ticketManagementProperties}
            permissions={permissions}
            style={style}
            defaultValue={editData}
            id={id}
            pageTitle="Edit Ticket"
            actionType="Edit"
          />
        )}
    </>
  );
};

export default Edit;
