import { Box, Button, Container, Grid, Tooltip, Typography } from '@mui/material';
import { useEffect, useReducer, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { IState } from '../../../redux/reducers/rootReducers';
import CustomSelect from '../../common/customSelect/CustomSelect';
import CustomFormLabel from '../../common/form-label/CustomFormLabel ';
import { singleRole, updateRole } from '../../../redux/actions/userManagement/role';
import { ReactSortable } from 'react-sortablejs';
import { handleTime } from '../../Dashboard/TicketManagement/TicketDashboardHolder';
import { debounce } from "lodash";
import { IRankingProps, rankingInitVal, rankingReducer } from './schema';
import { useNavigate } from 'react-router-dom';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

const RankingContent = (props:IRankingProps) => {
    const {systemTypes={}, rolesList=[],priorityList=[],statusObj={}} = props;
    const [state, customDispatch] = useReducer(rankingReducer, rankingInitVal);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const lastEventRef = useRef<any>(null)
    const onChangeRole = (e: any) => {
        console.log("eeeeeeeeeeeeeeeee",e);
        
        customDispatch({
            type : "ROLE_CHANGE",
            payload : e
        });
        if(e?.id){
            dispatch(singleRole(e.id,getRoleByIdCB))
        }
    }

    
    /* ====== POPULATE ROLES ====== */
    useEffect(()=>{
        if(rolesList?.length && rolesList?.length>0){
            customDispatch({
                type : "STORE_ROLE_OPTIONS",
                payload : rolesList.filter((i:any)=>!i.isSuperAdmin && !i.isSSPRole)
            });
        }
    },[rolesList?.length])

    useEffect(()=>{
        if(priorityList?.length && priorityList?.length>0){
            const tempObj:any = {}
            priorityList.map((x:any)=>{
                if(x.isActive){
                    tempObj[x.id] = x;
                }
            })
            customDispatch({type : "STORE_PRIORITIES_OBJ",payload : tempObj});
        }
    },[priorityList?.length])

   

    


    /* ====== GET ROLES & CALLBACK ====== */
    
    const getRoleByIdCB = (resStatus:string,resData:any={}) =>{
        if(resStatus ==="0"){
            const tempObj:any = {status:[], priority:[],otherPermissions:[],roleByIdVal:resData};
            resData.agentPermissionsList.map((i:any)=>{
                if(i.isAgentAdmin ){
                    if(i.adminTypes.name === "Priorities"){
                        if(state?.priorityObj?.[i?.adminChildIdLeavel1]?.isActive){ 
                            i.name = state?.priorityObj[i.adminChildIdLeavel1].name;
                            tempObj.priority.push(i)
                        };
                    }else if(i.adminTypes.name === "TicketStatus"){
                        if(statusObj?.[i?.adminChildIdLeavel1]?.isActive){ 
                            i.name = statusObj?.[i.adminChildIdLeavel1].displayName;
                            i.hint = statusObj?.[i.adminChildIdLeavel1].type === "TicketTaskStatus" ? " - (Task Status)" : "";
                            tempObj.status.push(i)
                        };
                    }else{
                        tempObj.otherPermissions.push(i) 
                    }
                }else{
                    tempObj.otherPermissions.push(i)
                }
            })
            tempObj.status = tempObj.status.sort((a:any, b:any) => a.ranking - b.ranking);
            tempObj.priority = tempObj.priority.sort((a:any, b:any) => a.ranking - b.ranking);
            customDispatch({type : "RECORDS_TO_SHOW",payload : tempObj});
        }
    } 




    /* ====== RANKING D&D HANDLERS ====== */
    const PriorityListCB = debounce((keyVal:string, val:any[]) => {
        console.log("PriorityListCB",keyVal,val);
        customDispatch({type : "SORT_PRIORITY",payload : val});
    }, 300);
    const TicketStatusListCB = debounce((keyVal:string, val:any[]) => {
        console.log("TicketStatusListCB",keyVal,val)
        customDispatch({type : "SORT_STATUS",payload : val});
    }, 300);

    useEffect(()=>{
        console.log("IPriorityListProps",state.sortedPriority, state.sortedStatus)
    },[state.sortedPriority, state.sortedStatus])




    /* ====== SUBMIT & CANCEL HANDLERS ====== */
    const cancelHandler = () =>{
        navigate('/adminPanel')
    }
    const submitCB = (resStatus: string, res: any) => {
        console.log("submitCB", resStatus, res)
        if(resStatus === "0"){
            // cancelHandler()
        }
    }
    const onSubmit = () =>{
        dispatch(updateRole({
            ...state.roleByIdVal,
            agentPermissionsList : [
                    ...state.otherPermissions, 
                    ...state.sortedPriority.map((i,index:number)=>{i.ranking = index+1;return i;}), 
                    ...state.sortedStatus.map((i,index:number)=>{i.ranking = index+1;return i;}), 
                ]
        },state.roleByIdVal?.id,submitCB))
    }
    return (
        <Box style={{marginTop:"40px"}} className="pb-5">
            <Grid container spacing={1}>
                <Grid item md={4}>
                    <aside className="aside">
                        <CustomFormLabel
                            labelName={"Choose Role"}
                            isMandotary={true}
                            classNames='sml-txt'
                        />
                        <CustomSelect
                            isOpen={false}
                            placeholder="Choose Role"
                            width={"100%"}
                            name="roleId"
                            isColor={false}
                            isDisabled={false}
                            isError={false}
                            helperText={``}
                            defaultValue={state.roleVal || null}
                            options={state?.roleList || []}
                            // handleChange={onChangeRole}
                            handleChange={(e: any) => {
                                const eventString = JSON.stringify(e);
                                if (lastEventRef.current !== eventString) {
                                  lastEventRef.current = eventString;
                                  onChangeRole(e)
                                }
            
                              }}
                            isMulti={false}
                            customClassNames="sml-txt-dropdown new-service"
                        />

                        <Box className="text-center pt-2">
                            <Button onClick={cancelHandler}>Cancel</Button> &nbsp; &nbsp; &nbsp;
                            <Button 
                                disabled={
                                    (state.roleVal && (state.sortedPriority.length>0 || state.sortedStatus.length>0)) ? false : true
                                } 
                                variant='contained' 
                                onClick={onSubmit}
                            >Update Role</Button>
                        </Box>
                    </aside>
                </Grid>
                <Grid item md={4}>
                    <Box className="statusBox white-bg">
                        <Typography>
                            <span>Priorities 
                                <Tooltip title="Rearrange the list below as you prefer.">
                                    <HelpOutlineOutlinedIcon />
                                </Tooltip>
                            </span>
                            <span className="rank">#Rank</span>
                        </Typography>
                        <hr />
                        {(state?.prioritiesToShow?.length && state?.prioritiesToShow?.length > 0) ? 
                            <PriorityList keyVal="Priorities" callback={PriorityListCB} items={state.prioritiesToShow}/>
                            : <Typography>No Records Found</Typography>
                        }
                    </Box>
                </Grid>
                <Grid item md={4}>
                    <Box className="statusBox white-bg">
                        <Typography>
                            <span>Statuses
                                <Tooltip title="Rearrange the list below as you prefer.">
                                    <HelpOutlineOutlinedIcon />
                                </Tooltip>
                            </span>
                            <span className="rank">#Rank</span></Typography>
                        <hr />
                        {(state?.statusToShow?.length && state?.statusToShow?.length > 0) ?
                            <PriorityList keyVal="TicketStatus" callback={TicketStatusListCB} items={state?.statusToShow}/> 
                            : <Typography>No Records Found</Typography>
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

interface IPriorityListProps {
    items : any[],
    callback : any,
    keyVal : string;
}
const PriorityList = (props:IPriorityListProps) =>{
    const {callback,items=[],keyVal} = props;
    const [sideBlocks, setSideBlocks] = useState<any[]>(items);
    useEffect(()=>{
        callback(keyVal,sideBlocks) 
    },[sideBlocks])
    return(
        <Box>
            <ReactSortable onAdd={(e) => {}} list={sideBlocks} setList={setSideBlocks}>
                {sideBlocks.map((block, blockIndex) => {
                    console.log("sideBlocks",block);
                    return (
                        <Typography 
                            key={blockIndex} 
                            className='item-val'> 
                            <span>
                                {block?.displayName || block.name}  {block?.hint}
                            </span>
                            <span className="rank">#{blockIndex+1}</span>
                        </Typography>)
                })}
            </ReactSortable>
        </Box>
    )
}

const mapStateToProps = (state:IState) => {
  return ({
    systemTypes: state?.systemTypeReducer?.systemTpesByName,
    rolesList: state?.roleReducer?.Data?.data,
    priorityList: state?.priorityReducer?.Data?.data,
    ticketStatusList : state?.TicketsReducer?.allResponses?.ticketStatus,
    statusObj : state?.TicketsReducer?.alldataStructure?.statusTypesById
    // /ticketStatus
  })
};

export default connect(mapStateToProps)(RankingContent);