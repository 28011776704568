import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { createRef, memo, useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { IState } from "../../../redux/reducers/rootReducers";
import { getTicketByID, quickUpdateTicket } from '../../../redux/actions/TicketsManagement/TicketsManagement';
import { TextBoxLatest } from '../../common/TextBoxLatest/TextBoxLatest';
import { iFormData } from '../../../pages/new-ticket/LatestTicket/Type';
import { returnFormValues } from '../../helpers/helperFunctions';
import AsyncDropDown from '../../../pages/new-ticket/LatestTicket/AsyncDrodown/AsyncDropDown';
import { getContactsForDropdown } from '../../../redux/actions/ContactManagement/Contact';
import { debounce } from 'lodash';
import { LabelFieldBox } from '../../postLogin/MyLicenses';
import BasicButton from '../../common/button/BasicButton';
import moment from 'moment';
import timeSpanToReadbleFormat from '../../../util/timeSpanToReadbleFormat';
import CustomFormLabel from '../../common/form-label/CustomFormLabel ';
import { getOrganisationsForDropdown } from '../../../redux/actions/CMDB/Organisation';



interface OnHoldMessagePopupProps {
    ticketDetails ?: any;
    dispatch?:any;
    statusObj ?:any;
}
const OnHoldMessagePopup = memo((props:OnHoldMessagePopupProps) => {
    const { dispatch, ticketDetails=null, statusObj={} } = props;
    const classes = useStyles();
    const [ticketInfo,setTicketInfo] = useState<any>(null)
    const noteRef:any = createRef();
    useEffect(() => {
        console.log("OnHoldMessagePopup",ticketDetails)
        if(ticketDetails?.ticketId){
            dispatch(getTicketByID(ticketDetails?.ticketId,getTicketByIDCB))
        }else{
            setTicketInfo(null)
        }
    }, [ticketDetails])
    const getTicketByIDCB = (resStatus:string, res:any)=>{
        if(resStatus === '0'){
            setTicketInfo(res)
        }
    };
    const onCloseHandler = useCallback(() =>{
        dispatch({
            type:"HOLD_TICKET_POPUP_INFO",
            payload : null
        })
    },[])

    
    const quickUpdateTicketCB = (res:any) =>{
        console.log("quickUpdateTicketCB",res)
        onCloseHandler();
    }
    const viewContent = useMemo(()=>{
        const onSubmit = (event: any) => {
            event.preventDefault();
            dispatch(quickUpdateTicket(
                {...ticketInfo, ticketLogs:{ 
                    ...ticketInfo?.ticketLogs, 
                    note : noteRef?.current?.value || "",
                    statusId : ticketDetails?.statusId,
                    ticketStatus : statusObj?.[ticketDetails?.statusId],
                } },
                quickUpdateTicketCB
            ));
        }
        return(
            <>
                {ticketDetails?.ticketId  && 
                <Box className={`${classes.popupHolder} ${classes.textWithSpanTitle}`}>
                    <Box className="modal-dialog">
                    <Box className="modal-content">
                        <Box className="modal-header">
                         <Box className="row m-0 rsolveTicketTitle txt-ellipsis align-items-center gap15">
                            <Avatar
                                className="sml-avatar"
                                sx={{ height: 25, width: 25 }}
                                alt={ticketInfo?.ticketLogs?.contacts?.knownAs || "PP"}
                                src={`${ticketInfo?.ticketLogs?.contacts?.profilePicturePath || ""}`}
                            />
                            <Typography className="txtCapitalize ">
                                <span><span className="txt-primary">#{ticketInfo?.id} </span> {ticketInfo?.ticketLogs?.title} </span>
                            </Typography>
                        </Box>
                        <Typography className="cursor-pointer" onClick={onCloseHandler}>
                            <CloseIcon className="cursor-pointer" />
                        </Typography>
                        </Box>
                        <Box className="modal-body resolve-popup">
                            <Box className="p-1 pr-0">
                                <Grid container  spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <LabelFieldBox label={"Title"} value={ticketInfo?.ticketLogs?.title} />
                                        <LabelFieldBox label={"Description"} value={ticketInfo?.ticketLogs?.description} />
                                        <LabelFieldBox label={"Due Date"} value={moment(ticketInfo?.ticketLogs?.dueDateTime).format("L LT")} />
                                        
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <form onSubmit={onSubmit}>
                                            {ticketInfo?.ticketLogs?.id && <>
                                                <Box>
                                                    <TextBoxLatest
                                                        placeholder="Plase Enter Note"
                                                        title={"Note"}
                                                        ref={noteRef}
                                                        multiline={true}
                                                    />
                                                </Box>
                                                <Box className="pt-1 text-center">
                                                    <BasicButton
                                                        type={"button"}
                                                        label={"Close"}
                                                        size="small"
                                                        variant={"outlined"}
                                                        onClick={onCloseHandler}
                                                    /> &nbsp; &nbsp;
                                                    <BasicButton
                                                        type={"submit"}
                                                        label={"Submit"}
                                                        size="small"
                                                        variant={"contained"}
                                                    />
                                                </Box>
                                                </>
                                            }
                                        </form>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                    </Box>
                </Box>
                }
            </>);
    },[ticketInfo])
    return (<>
      {viewContent}
    </>)
  })




const validationHelper = (payload:iFormData,status:string)=>{
    console.log("validationHelper",payload,status)
    let temp:any = {
        isValid : true,
        errors:{}
    }
    if(status !== "Resolved"){
        if(!payload?.thirdPartyRefNo?.trim || payload?.thirdPartyRefNo?.trim() === ""){
            temp.isValid = false;
            temp.errors.thirdPartyRefNo = "Please Enter Third Party Ref Number";
        }
        if(!payload?.contactOrganisationId?.trim || payload?.contactOrganisationId?.trim() === ""){
            temp.isValid = false;
            temp.errors.contactOrganisationId = "Please Choose Third Party Organisation";
        }
    }else{
        if(!payload?.note?.trim || payload?.note?.trim() === ""){
            temp.isValid = false;
            temp.errors.note = "Please Enter Resolve Notes";
        }
    }

    return temp;
}
const useStyles = makeStyles(() =>
  createStyles({
    popupHolder: {
    position: "fixed",
    top: 0,
    left: 0,
    background: "#0005",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    zIndex: 1399,
    "& .modal-dialog": {
        display: "flex",
        height: '100%',
        justifyContent: "center",
        alignItems: "center",
    },
    "& .modal-header": {
        display: "flex",
        alignItems: "center",
        padding: "20px",
        justifyContent: "space-between",
        borderBottom: "1px solid #ddd",
        paddingBottom: "10px"
    },
    "& .modal-body": {
        maxHeight: "calc(100vh - 200px)",
        overflowY: "scroll",
        padding: "0px"
    },
    "& .modal-body > div": {
        margin: "0 !important",
        maxWidth: "calc(100% - 15px)"
    },
    "& .modal-content": {
        background: "#fff",
        width: "100%",
        maxWidth: "700px",
        borderRadius: "5px",
        //padding: "20px",
        "& img.Contactprofile": {
        width: "calc(100% - 10px)",
        },
        "& img": {
        objectFit: "cover"
        }
    }
    },
    textWithSpanTitle: {
        "& .title-txts":{
            lineHeight: '1.5',
            marginBottom: 10,
            fontSize: '13px',
            "& > span": {
            display: 'block',
            fontSize: '11px',
            marginBottom: '2px',
            lineHeight: '1',
            color: "#5559"//mode.link,
            }
        }
      },
      txtWithLink: {
        fontSize: ".7em",
        display: "flex",
        alignItems: "center",
        gap: "5px",
        "& span": {
          wordBreak: "break-all"
        },
        "& a": {
          fontSize: ".7em",
          textDecoration: "none",
          display: "inline-block",
          lineHeight: 1,
          "& svg": {
            fontSize: "12px"
          }
        }
      }
}))


const mapStateToProps = (state:IState) => {
    return ({
        ticketDetails : state?.TicketViewReducer?.holdTicketPopupInfo,
        statusObj : state?.TicketsReducer?.alldataStructure?.statusTypesById
    })
  };
  
export default connect(mapStateToProps)(OnHoldMessagePopup);